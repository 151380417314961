<template>
  <div class="container">
    <div class="container__outer">
      <div data-flex="column" class="container__inner">
        <h2
          data-font="upper action main light lh-small"
          data-space="pb4 pt2 px4"
          v-html="title[lang]"
        ></h2>
        <p
          class="legal-mentions"
          data-font="text base light lh-medium"
          data-space="pb2 px4"
          v-html="text[lang]"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["lang"],
  data() {
    return {
      title: {
        fr: `Mentions légales`,
        en: `Legal mentions`,
      },
      text: {
        fr: `EVARINVEST AM est attentif à la protection des données personnelles de ses clients et partenaires.
<br>Vous trouverez ci-dessous tous les renseignements nécessaires à la compréhension des traitements qui peuvent être effectués sur vos données personnelles conformément à la règlementation RGPD.
<br><br><span data-font="light big">Nos engagements</span>
<br><br>EVARINVEST AM, en tant que responsable de traitement des données, respecte les principes suivants :
<ul>
<li>vos données sont utilisées uniquement pour des finalités explicites, légitimes et déterminées en lien avec notre activité en tant que société de gestion de portefeuille,</li>
<li>seules les données qui nous sont utiles sont collectées,</li>
<li>nous ne conservons pas vos données au-delà de la durée nécessaire pour les opérations pour lesquelles elles ont été collectées ou de celles prévues par les normes et autorisations par la loi (telles que les prescriptions légales),</li>
<li>nous communiquons vos données aux seuls intermédiaires, prestataires ou organismes professionnels habilités qui en ont besoin dans le cadre de nos activités.</li>
</ul>
<br><span data-font="light big">L’utilisation de vos données</span>
<br><br>Lorsque vous consultez ce site Internet, EVARINVEST AM collecte vos données (<span data-font="light-ita">en particulier votre adresse IP et le cas échéant, votre prénom, votre nom, votre numéro de téléphone ou votre adresse e-mail</span>) par le biais de formulaires.
<br>Vos données personnelles sont susceptibles d’être collectées par EVARINVEST AM uniquement dans les cas suivants :
<ul>
<li><span data-font="bold">Réponse à une offre d’emploi ou envoi de candidature spontanée :</span>
<span class="block" data-space="mt1">Vos données personnelles sont conservées sur notre serveur pendant la procédure de recrutement, puis détruites au bout de 2 ans si votre candidature n’a pas été retenue.</span>
<span class="block" data-space="mt1"><span data-font="light-ita">Pour faire modifier ou supprimer vos données personnelles de notre serveur vous pouvez adresser un email à :</span> evarinvest@evarinvest.com</span></li>
<li><span data-font="bold">Utilisation du formulaire de contact de notre site Internet, prise de contact par email pour un motif professionnel :</span>
<span class="block" data-space="mt1">Vos coordonnées professionnelles sont enregistrées dans notre base de contact, et vous êtes susceptibles de recevoir des emails de notre part. Vos coordonnées sont conservées tant qu’elles sont pertinentes pour notre activité, et peuvent être enrichies en cas de contact ultérieur. Elles sont supprimées lorsque EVARINVEST AM n’a plus d’intérêt légitime à les conserver, et par défaut après trois ans d’inactivité respective.</span>
<span class="block" data-space="mt1"><span data-font="light-ita">Pour faire modifier ou supprimer vos données personnelles de notre base de contact, ou pour maintenir votre contact avec EVARINVEST AM, vous pouvez adresser un email à :</span> evarinvest@evarinvest.com</span></li>
<li><span data-font="bold">Initiation d’une relation contractuelle entre EVARINVEST AM et votre société :</span>
<span class="block" data-space="mt1">Vos coordonnées professionnelles sont enregistrées dans notre base de contact, et peuvent être enrichies en cas de contact ultérieur. Vos données personnelles sont également enregistrées sur notre serveur si vous êtes bénéficiaire effectif d’une opération réalisée avec EVARINVEST AM. Vos données personnelles sont conservées pendant une durée correspondant au maximum entre l’exécution du contrat et les obligations légales ou réglementaires qui en découlent.</span></li>
</ul>
<br>EVARINVEST AM s’engage à ce que la collecte et l’utilisation de ces données à des fins d’information ou de communication externe soient conformes aux dispositions légales et réglementaires.
<br><br>Des données indirectement nominatives seront également collectées à des fins statistiques, afin d’optimiser votre connexion et votre navigation (voir informations sur les cookies ci-après).
<br><br><span data-font="light big">La securité</span>
<br><br>Nous nous engageons à assurer la sécurité de vos données en mettant en place une protection des données renforcée par l’utilisation de moyens de sécurisation physiques et logiques conformes aux règles de l’art et aux normes qui nous sont imposées.
<br><br>Vos données personnelles sont accessibles uniquement aux employés d’EVARINVEST AM concernés par votre relation avec nous. Toutefois, EVARINVEST AM pourra occasionnellement être amenée à transmettre vos informations à d’autres sociétés, ainsi qu’à des tiers qui nous fournissent des services ou qui fournissent des services pour notre compte.
<br><br>Elles comprennent un contrôle strict des transferts de vos données, en particulier lorsqu’ils se font hors de l’Union européenne et sa législation protectrice, comme de requérir préalablement et systématiquement les autorisations nécessaires des autorités de protection des données à caractère personnel telles que la CNIL.
<br><br><span data-font="light big">Vos droits / exercice de vos droits</span>
<br><br>Sous réserve de la réglementation en vigueur, vous pouvez à tout moment disposer de vos droits vous permettant de :
<ul>
<li>accéder à vos données,</li>
<li>demander leur rectification en cas d’erreur,</li>
<li>demander leur suppression,</li>
<li>demander la limitation de leur traitement,</li>
<li>demander leur portabilité,</li>
<li>vous opposer à leur traitement,</li>
<li>définir des directives relatives à leur sort après votre décès.</li>
</ul>
<br>Pour mieux connaître vos droits, vous pouvez vous rendre sur le site de la CNIL (<a href="www.cnil.fr/fr/comprendre-vosdroits" data-font="menu underline" target="_blank">www.cnil.fr/fr/comprendre-vosdroits</a>).
<br><br>Vous pouvez exercer les droits susmentionnés en adressant un courrier électronique à evarinvest@evarinvest.com ou un courrier postal à :
<br><br>EVARINVEST AM, 48 bd de la Tour-Maubourg 75007 PARIS.
<br><br>En cas d’insatisfaction quant à la réponse apportée à votre demande d’exercice de vos droits, vous pouvez saisir la Commission Nationale Informatique et Libertés (<a href="www.cnil.fr" data-font="menu underline" target="_blank">www.cnil.fr</a>).
<br><br><span data-font="light big">Plus d’informations</span>
<br><br>Coordonnées professionnelles du responsable du traitement :
<br><br><span class="w100 block" data-font="center">EVARINVEST AM
<br>48 bd de la Tour-Maubourg
<br>75007 PARIS
<br>00.33.1.87.76.06.57
<br>evarinvest@evarinvest.com</span>
<br>Vous êtes actuellement connecté sur le site Internet d’EVARINVEST AM.
<br><br>SAS, au capital social de 230 000€
<br>RCS 877807800 Paris - APE : 6619B
<br>Numéro SIREN : 877 807 800
<br>Code SIRET : 877 807 800 00017
<br>N° TVA intra-communautaire : FR94877807800
<br><br>Siège social : 48 bd de la Tour-Maubourg 75007 PARIS
<br>Téléphone : 01.87.76.06.57
<br>Courriel : evarinvest@evarinvest.com
<br><br>EVARINVEST AM est une société de gestion de portefeuille de droit français agréée par l’Autorité des Marchés Financiers (AMF) sous le n°GP-20000011.
<br>Adresse de l'AMF :
<br>17 place de la Bourse
<br>75 002 Paris Cedex 02
<br><br>Directeur de publication : Selma SEKKAT
<br>Responsable de la rédaction : Selma SEKKAT
<br><br>Hébergeur : Ce site est hébergé par Digital Ocean
<br><br><span data-font="light big">Droit d’auteur et propriété intellectuelle</span>
<br><br>La navigation sur ce site est soumise à la réglementation en vigueur : de ce fait, l’ensemble des documents, informations disponibles sur le site, sont la propriété exclusive d’EVARINVEST AM et sont protégés par le code de la propriété intellectuelle.
<br><br>Toute reproduction et toute utilisation du contenu du site autre que la consultation individuelle et privée sont interdites, sauf autorisation.
<br><br>Tout lien hypertexte renvoyant vers notre site devra faire l’objet d’une autorisation spécifique préalable. Pour l’obtenir, vous pouvez envoyer un courrier à :
<br><br><span class="w100 block" data-font="center"><span data-font="bold">EVARINVEST AM</span>
<br>48 bd de la Tour-Maubourg
<br>75007 PARIS</span>`,
        en: `EVARINVEST AM is attentive to the protection of the personal data of its customers and partners.
<br>Below you will find all the information necessary to understand the processing that can be carried out on your personal data in accordance with the GDPR regulations in force on 05/25/2018.
<br><br><span data-font="light big">Our engagements</span>
<br><br>EVARINVEST AM, as data controller, respects the following principles :
<ul>
<li>your data is used only for explicit, legitimate and determined purposes in connection with our activity as a portfolio management company,</li>
<li>only the data that are useful to us are collected,</li>
<li>we do not keep your data beyond the time necessary for the operations for which they were collected or those provided for by standards and authorizations by law (such as legal requirements),</li>
<li>we communicate your data only to authorized intermediaries, service providers or professional organizations that need it in the context of our activities.</li>
</ul>
<br><span data-font="light big">The use of your data</span>
<br><br>When you visit this website, EVARINVEST AM collects your data (<span data-font="light-ita">in particular your IP address and, where applicable, your first name, last name, telephone number or e-mail address</span>) through forms.
<br>Your personal data may be collected by EVARINVEST AM only in the following cases :
<ul>
<li><span data-font="bold">Response to a job offer or sending of an unsolicited application :</span>
<span class="block" data-space="mt1">Your personal data is kept on our server during the recruitment process, then destroyed after 2 years if your application has not been accepted.</span>
<span class="block" data-space="mt1"><span data-font="light-ita">To modify or delete your personal data from our server, you can send an email to :</span> evarinvest@evarinvest.com</span></li>
<li><span data-font="bold">Use of the contact form on our website, making contact by email for professional reasons :</span>
<span class="block" data-space="mt1">Your professional contact details are recorded in our contact database, and you are likely to receive emails from us. Your contact details are kept as long as they are relevant to our business, and may be enhanced in the event of subsequent contact. They are deleted when EVARINVEST AM no longer has a legitimate interest in keeping them, and by default after three years of respective inactivity.</span>
<span class="block" data-space="mt1"><span data-font="light-ita">To modify or delete your personal data from our contact database, or to maintain your contact with EVARINVEST AM, you can send an email to :</span> evarinvest@evarinvest.com</span></li>
<li><span data-font="bold">Initiation of a contractual relationship between EVARINVEST AM and your company :</span>
<span class="block" data-space="mt1">Your professional details are recorded in our contact data base, and can be enriched in the event of subsequent contact. Your personal data is also saved on our server if you are the beneficial owner of a transaction carried out with EVARINVEST AM. Your personal data is kept for a period corresponding to the maximum between the execution of the contract and the resulting legal or regulatory obligations.</span></li>
</ul>
<br>EVARINVEST AM undertakes to ensure that the collection and use of this data for information or external communication purposes comply with legal and regulatory provisions.
<br><br>Indirectly nominative data will also be collected for statistical purposes, in order to optimize your connection and browsing (see information on cookies below).
<br><br><span data-font="light big">Security</span>
<br><br>We are committed to ensuring the security of your data by implementing enhanced data protection through the use of physical and logical security means that comply with good practice and the standards imposed on us.
<br><br>Your personal data is only accessible to EVARINVEST AM employees affected by your relationship with us. However, EVARINVEST AM may occasionally be required to transmit your information to other companies, as well as to third parties who provide services to us or who provide services on our behalf.
<br><br>They include a strict control of the transfers of your data, in particular when they take place outside the European Union and its protective legislation, such as to request beforehand and systematically the necessary authorizations of the authorities of protection of personal data such as the CNIL.
<br><br><span data-font="light big">Your rights / exercise of your rights</span>
<br><br>Subject to the regulations in force, you can have your rights at any time allowing you to :
<ul>
<li>access your data,</li>
<li>request their rectification in the event of an error,</li>
<li>request their deletion,</li>
<li>request the limitation of their processing,</li>
<li>request their portability,</li>
<li>object to their processing,</li>
<li>set guidelines for their fate after your death.</li>
</ul>
<br>To learn more about your rights, you can visit the CNIL website (<a href="www.cnil.fr/fr/comprendre-vosdroits" data-font="menu underline" target="_blank">www.cnil.fr/fr/comprendre-vosdroits</a>).
<br><br>You can exercise the aforementioned rights by sending an email to evarinvest@evarinvest.com or a post to :
<br><br>EVARINVEST AM, 48 bd de la Tour-Maubourg – 75007 PARIS.
<br><br>If you are dissatisfied with the response to your request to exercise your rights, you can contact the Commission Nationale Informatique et Libertés (<a href="www.cnil.fr" data-font="menu underline" target="_blank">www.cnil.fr</a>).
<br><br><span data-font="light big">More information</span>
<br><br>Professional contact details of the controller :
<br><br><span class="w100 block" data-font="center">EVARINVEST AM
<br>48 bd de la Tour-Maubourg
<br>75007 PARIS
<br>00.33.1.87.76.06.57
<br>evarinvest@evarinvest.com</span>
<br>You are currently connected to the EVARINVEST AM website.
<br><br>SAS, with a share capital of 230 000€.
<br>RCS 877807800 Paris - APE : 6619B
<br>SIREN Number : 877 807 800
<br>SIRET Code : 877 807 800 00017
<br>Intra-community VAT N° : FR94877807800
<br><br>Head office : 48 bd de la Tour-Maubourg 75007 PARIS
<br>Phone : 01.87.76.06.57
<br>Email : evarinvest@evarinvest.com
<br><br>EVARINVEST AM is a portfolio management company under French law approved by the Autorité des Marchés Financiers (AMF) under the n°GP-20000011.
<br>Address of the AMF :
<br>17 place de la Bourse
<br>75002 Paris Cedex 02
<br><br>Director of Publication : Selma SEKKAT
<br>Editorial Manager : Selma SEKKAT
<br><br>Host : This website is hosted by DigitalOcean
<br><br><span data-font="light big">Copyright and intellectual property</span>
<br><br>Visiting this website is subject to the current regulations: therefore, all documents and information available on the website are the exclusive property of EVARINVEST AM and are protected by the code of intellectual property.
<br><br>Any reproduction and any use of the content of the website other than individual and private consultation are prohibited, unless authorized.
<br><br>Any hypertext link to our website must be subject to specific prior authorization. To obtain it, you can send a letter to :
<br><br><span class="w100 block" data-font="center"><span data-font="bold">EVARINVEST AM</span>
<br>48 bd de la Tour-Maubourg
<br>75007 PARIS</span>`,
      },
    };
  },
};
</script>