<template>
  <div class="container">
    <div class="container__outer">
      <div class="scroll"></div>
      <div data-flex="column" class="container__inner container__inner--small">
        <div data-space="px4 py2">
          <h2 class="slide4 slide-down" data-font="upper action light subtitle lh-tiny left-s" v-html="text.title[lang]"></h2>
          <h3 class="slide3 slide-down" data-font="text light subtitle lh-tiny left-s" v-html="text.subtitle[lang]"></h3>
          <div class="slide2 slide-down slider" data-space="mt4">
            <p v-html="content[lang]"
               class="slider__item slider__item--left nolb-s"
               :class="{ 'slider__item--active': currentItem === null }"
               data-font="text light body lh-big"></p>
            <p v-for="(item, i) in items"
               v-html="item.content[lang]"
               class="slider__item nolb-s"
               :class="{ 'slider__item--active': currentItem === i, 'slider__item--left': currentItem > i, 'slider__item--right': currentItem < i || currentItem === null }"
               data-font="upper accent bold big left-s"></p>
          </div>
          <div class="slide1 slide-down slider-nav"
               data-space="mt2"
               @mouseenter="hoverIn"
               @mouseleave="hoverOut"
               :class="[ currentItem !== null ? 'slider-nav--' + currentItem : 'slider-nav--' + hoveredItem, { 'slider-nav--active': currentItem !== null, ['slider-nav--active-' + currentItem]: currentItem !== null } ]">
            <button v-for="(item, i) in items"
                    class="slider-nav__item"
                    :class="{ 'slider-nav__item--active': currentItem === i }"
                    @click="currentItem = currentItem !== i ? i : null"
                    @mouseenter="hoveredItem = i">{{ i + 1 }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import cursorToggle from '../assets/js/cursor-toggle.js'

  export default {
    mixins: [ cursorToggle ],
    props: [ 'lang' ],
    data () {
      return {
        currentItem: null,
        hoveredItem: 1,
        text: {
          title: {
            fr: 'Double effet vertueux',
            en: 'Optionality and complexity'
          },
          subtitle: {
            fr: 'Optionalité et complexité',
            en: 'A double positive effect'
          }
        },
        content: {
          fr: 'Les marchés financiers sont un système complexe. <br>L’asymétrie des options offre des résultats bien adaptés <br>pour aborder leur complexité.',
          en: `Financial Markets are Complex systems! <br>The Modern Financial Theory fails to take their complexity into account. <br>Options <span data-font="bold">asymmetry</span> is the best answer to complexity.`
        },
        items: [
          {
            content: {
              fr: 'Non-linéarité',
              en: 'Non-linearity'
            }
          },
          {
            content: {
              fr: 'Discontinuité',
              en: 'Discontinuity'
            }
          },
          {
            content: {
              fr: 'Interdépendance <br>des acteurs financiers',
              en: 'Endogenous instability <br>and multiple interdependences'
            }
          },
          {
            content: {
              fr: 'Irréversibilité <br>et mémoire du passé',
              en: 'Irreversibility <br>and memory of the past'
            }
          },
          {
            content: {
              fr: 'Émergence d’une auto-organisation <br>après des phases de chaos',
              en: 'Self-organization: auto-organization <br>spontaneously appears after chaos'
            }
          }
        ]
      }
    }
  }
</script>