<template>
  <div id="app">
    <transition name="fade">
      <div class="main" v-show="userOk && !introPlaying">
        <div class="panel" :style="{ transform: aperture }">
          <Scene :cursor="cursor"></Scene>
          <transition name="fade" mode="out-in" duration="1100">
            <router-view v-if="display" :lang="lang" @hovered="hovered" />
          </transition>
          <h1 class="logo slide2 slide-down"
              @mouseenter="cursor = false"
              @mouseleave="cursor = !menuActive">
            <router-link to="/">
              <img src="./assets/images/eva-logo.svg" />
            </router-link>
          </h1>
          <div class="top-right-menu slide1 slide-left">
            <a class="analytics-btn"
               href="http://analytics.evarinvest.com"
               target="_blank"
               @mouseenter="cursor = false"
               @mouseleave="cursor = !menuActive">
              <img class="analytics-btn__img" src="./assets/images/eva-flake-green.svg"/>
              <span class="analytics-btn__text">Analytics</span>
            </a>
            <div class="lang"
                 @mouseenter="cursor = false"
                 @mouseleave="cursor = !menuActive">
              <button class="lang__item" :class="{ 'lang__item--selected': lang === 'fr' }" @click="() => { switchLang('fr') }">fr</button>
              <div class="lang__separator"></div>
              <button class="lang__item" :class="{ 'lang__item--selected': lang === 'en' }" @click="() => { switchLang('en') }">en</button>
            </div>
          </div>
          <div class="overlay" :class="{ 'overlay--active': menuActive }"></div>
        </div>
        <div class="sidebar slide3 slide-right" :class="{ 'sidebar--active': menuActive }">
          <div class="burger" :class="{ 'burger--active': menuActive }"
               @click="menuActive = !menuActive"
               @mouseenter="cursor = false"
               @mouseleave="cursor = !menuActive">
            <div class="burger__inner burger__inner-top"></div>
            <div class="burger__inner burger__inner-mid"></div>
            <div class="burger__inner burger__inner-bot"></div>
          </div>
          <transition name="rotate">
            <Menu v-show="menuActive" :menu-active="menuActive" :lang="lang"></Menu>
          </transition>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="introPlaying || !userOk"
           :class="{ 'intro--active': introPlaying }"
           class="intro">
        <video ref="intro" :width="vidSize.x" :height="vidSize.y">
          <source src="./assets/videos/intro.mp4" type="video/mp4">
        </video>
      </div>
    </transition>
    <transition name="fade" mode="out-in" duration="1100">
      <Disclaimer v-if="!userOk" :lang="lang" @useraccepted="acceptUser"></Disclaimer>
    </transition>
  </div>
</template>

<script>
  import Menu from './components/Menu'
  import Disclaimer from './components/Disclaimer'
  import Scene from './components/Scene'
  import touch from './assets/js/vue-touch'

  export default {
    mixins: [ touch ],
    components: {
      Menu,
      Disclaimer,
      Scene
    },
    data () {
      return {
        vidSize: {
          x: 1920,
          y: 1080
        },
        introLoaded: false,
        userOk: false,
        introPlaying: false,
        lang: 'en',
        menuActive: false,
        deg: null,
        openArray: {
          min: 30,
          max: 70
        },
        display: true,
        canScroll: true,
        scrollTimeout: 2000,
        cursor: true,
        swipeAmount: 60
      }
    },
    created () {
      let lang = localStorage.getItem('lang') || navigator.language.split('-')[0].toLowerCase()
      if (lang !== 'fr' && lang !== 'en') { lang = 'en' }
      this.lang = lang

      const ok = sessionStorage.getItem('userAccepted')
      if (ok === 'true') {
        this.userOk = true
      }

      this.resize()
      window.addEventListener('resize', this.resize)
      window.addEventListener('wheel', this.scrollAction)
      window.addEventListener('touchstart', () => {
        window.removeEventListener('wheel', this.scrollAction)
      })
    },
    mounted () {
      window.hideLoader()
      if (!this.userOk) {
        this.$refs.intro.addEventListener('canplaythrough', () => {
          this.introLoaded = true
        })
        this.$refs.intro.addEventListener('ended', () => {
          this.introPlaying = false
        })
      }
    },
    destroyed () {
      window.removeEventListener('resize', this.calcDeg)
      window.removeEventListener('wheel', this.scrollAction)
    },
    computed: {
      aperture () {
        return this.menuActive ? this.deg : ''
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler(to, from) {
          this.menuActive = false
          this.cursor = true
          document.title = to.name.charAt(0).toUpperCase()+ to.name.slice(1) + ' | Evarinvest';
        }
      },
      introLoaded () {
        if (this.userOk && this.introLoaded) {
          this.introPlaying = true
          this.$refs.intro.play()
        }
      }
    },
    methods: {
      resize () {
        this.calcDeg()
        if (this.introPlaying || !this.userOk) {
          this.introSize()
        }
      },
      swipeDown () {
        if (this.canScroll && !this.menuActive) {
          const cont = document.querySelector('.container')
          if (cont.scrollTop === 0) {
            this.navigate(-1)
          }
        }
      },
      swipeUp () {
        if (this.canScroll && !this.menuActive) {
          const cont = document.querySelector('.container')
          if (cont.scrollHeight - window.innerHeight <= cont.scrollTop) {
            this.navigate(1)
          }
        }
      },
      scrollAction (e) {
        if (this.canScroll && !this.menuActive) {
          const cont = document.querySelector('.container')
          const dir = e.deltaY > 0 ? 1 : -1
          if (cont.scrollHeight - window.innerHeight === cont.scrollTop && dir === 1) {
            this.navigate(1)
          } else if (cont.scrollTop === 0 && dir === -1) {
            this.navigate(-1)
          }
        }
      },
      navigate (direction) {
        if (this.canScroll) {
          const route = this.$routeName(this.$route.name, direction)
          if (route !== null) {
            this.canScroll = false
            window.setTimeout(() => {
              this.canScroll = true
            }, this.scrollTimeout)
            this.$router.push({
              name: route
            })
          }
        }
      },
      introSize () {
        const w = {
          w: window.innerWidth,
          h: window.innerHeight
        }
        if (w.w < this.vidSize.x && w.h < this.vidSize.y) {
          const s = Math.max(w.w / this.vidSize.x, w.h / this.vidSize.y)
          this.vidSize.x *= s
          this.vidSize.y *= s
        }
      },
      calcDeg () {
        const ww = window.innerWidth
        this.deg = 'rotateY(-' + (this.openArray.max - (ww - 350) / 2210 * (this.openArray.max - this.openArray.min)) + 'deg)'
      },
      switchLang (lang) {
        localStorage.setItem('lang', lang)
        if (this.display) {
          this.display = false
          window.setTimeout(() => {
            this.display = true
            this.lang = lang
          }, 750)
        }
      },
      acceptUser () {
        this.userOk = true
        sessionStorage.setItem('userAccepted', true)
        if (this.introLoaded) {
          this.introPlaying = true
          this.$refs.intro.play()
        }
      },
      hovered (state) {
        this.cursor = state
      }
    }
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300&display=swap');
  @import "assets/scss/index";
</style>