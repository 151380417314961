<template>
  <div class="container">
    <div class="container__outer">
      <div class="scroll"></div>
      <div data-flex="column items-start" class="container__inner">
        <div class="bg-img">
          <img class="w100" src="../assets/images/eva-flake.svg"/>
        </div>
        <div class="relative">
          <h2 class="slide2 slide-down relative"
              data-space="px4"
              data-font="upper text light main lh-small"
              v-html="text.title[lang]"></h2>
          <h3 class="slide1 slide-down relative"
              data-space="px4"
              data-font="action light main lh-small"
              v-html="text.subtitle[lang]"></h3>
          <p v-if ="text.desc[lang] !== null"
             class="slide1 slide-down relative half"
             data-font="text light base lh-big"
             data-space="px4 pt2"
             v-html="text.desc[lang]"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [ 'lang' ],
    data () {
      return {
        text: {
          title: {
            fr: 'Evarinvest asset management',
            en: 'Evarinvest asset management'
          },
          subtitle: {
            fr: 'La complexité<br>en toute simplicité',
            en: 'Complexity<br>with simplicity'
          },
          desc: {
            fr: null,
            en: 'Our simple approach to financial markets, recognizing their COMPLEXITY in order to generate sustained investment performances and promote robust risk management practices.'
          }
        }
      }
    }
  }
</script>