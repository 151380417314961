<template>
  <div class="menu">
    <div data-col="grow" data-flex="justify-center column">
      <component
        v-for="(item, i) in items"
        :is="item.route ? 'router-link' : 'a'"
        class="menu-item"
        :to="item.route"
        :href="item.link"
        :class="[
          item.css || '',
          {
            'menu-item--active': $route.name === item.route,
            'menu-item--animate': !menuActive,
          },
        ]"
        :style="[
          menuActive
            ? {
                transition:
                  'transform 0.2s ease ' +
                  (0.3 + i * 0.075) +
                  's, opacity 0.2s ease ' +
                  (0.3 + i * 0.075) +
                  's, padding-left .2s ease',
              }
            : '',
        ]"
        :target="item.link ? '_blank' : null"
      >
        {{ item.label[lang] }}
      </component>
    </div>

    <div data-flex="column">
      <router-link
        to="lettre-aux-porteurs"
        data-font="base menu lh-small"
        v-html="lp[lang]"
      ></router-link>
      <router-link
        data-space="mt1"
        to="mentions-legales"
        data-font="base menu lh-small"
        v-html="ml[lang]"
      ></router-link>
      <router-link
        data-space="mt1"
        v-if="ir[lang]"
        to="informations-reglementaires"
        data-font="base menu lh-small"
        v-html="ir[lang]"
      ></router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["lang", "menuActive"],
  data() {
    return {
      lp: {
        fr: "Lettre aux porteurs",
        en: "Letter to shareholders",
      },
      ml: {
        fr: "Mentions légales",
        en: "Legal mentions",
      },
      ir: {
        fr: "Informations réglementaires",
        en: `Regulatory Information`,
      },
      items: [
        {
          route: "concept",
          label: {
            fr: "Concept",
            en: "Our Philosophy",
          },
        },
        {
          route: "genese",
          label: {
            fr: "La genèse",
            en: "About us",
          },
        },
        {
          route: "entropie",
          label: {
            fr: "Entropie",
            en: "Entropy",
          },
        },
        {
          route: "complexite",
          label: {
            fr: "La complexité",
            en: "Complexity",
          },
        },
        {
          route: "valeurs",
          label: {
            fr: "Nos valeurs",
            en: "Our values",
          },
        },
        {
          route: "sicav-asymetrix",
          label: {
            fr: "SICAV Asymétrix",
            en: "SICAV Asymétrix",
          },
        },
        {
          link: "http://analytics.evarinvest.com/",
          label: {
            fr: "Analytics",
            en: "Analytics",
          },
        },
        {
          route: "contact",
          label: {
            fr: "Contact",
            en: "Contact",
          },
          css: "menu-item--special",
        },
      ],
    };
  },
};
</script>