<template>
  <div class="container">
    <div class="container__outer">
      <div class="scroll"></div>
      <div data-flex="items-center column-s items-start-s" class="container__inner">
        <div id="anim-values" class="bg-anim"></div>
        <div data-space="px4 py2" class="relative">
          <h2 class="slide5 slide-down"
              data-font="text light main nowrap lh-tiny"
              data-space="mr1 mb2"
              v-html="text.title[lang]"></h2>
          <h3 v-for="(item, i) in items"
              class="tab-nav__item slide-down"
              :class="[ 'slide' + (items.length - i), { 'tab-nav__item--active': currentItem === i } ]"
              @mouseenter="hoverIn"
              @mouseleave="hoverOut"
              @click="() => { changeCurrent(i) }">{{ item.title[lang] }}</h3>
        </div>
        <div class="tab" data-space="px4 my2">
          <p class="tab__item tab__item--top slide4 slide-down"
             :class="{ 'tab__item--active': currentItem === null }"
             data-font="text light body lh-big" v-html="text.content[lang]"></p>
          <p v-for="(item, i) in items"
             class="tab__item slide4 slide-down"
             :class="{ 'tab__item--active': currentItem === i, 'tab__item--top': currentItem > i, 'tab__item--bottom': currentItem < i || currentItem === null }"
             data-font="text light body lh-big" v-html="item.content[lang]"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import cursorToggle from '../assets/js/cursor-toggle.js'
  import lottie from 'lottie-web'

  export default {
    mixins: [ cursorToggle ],
    props: [ 'lang' ],
    mounted () {
      this.anim = lottie.loadAnimation({
        container: document.getElementById('anim-values'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: './lottie/values.json'
      })
      this.anim.goToAndStop(0, true)
    },
    methods: {
      changeCurrent (i) {
        if (!this.playing) {
          this.playing = true

          const from = this.timeStamps[this.currentItem !== null ? this.currentItem + 1 : 0]
          const to = this.timeStamps[i !== this.currentItem ? i + 1 : 0]

          this.anim.setSpeed(to === 2 || from === 2 ? 2.5 : 1.2)

          this.currentItem = this.currentItem !== i ? i : null
          this.anim.playSegments([
            this.animState[from],
            this.animState[to]
          ], true)

          window.setTimeout(() => {
            this.playing = false
          }, this.duration * 1000)
        }
      }
    },
    data () {
      return {
        playing: false,
        duration: 0.8,
        anim: null,
        timeStamps: [0, 1, 2, 1],
        animState: [0, 51, 133],
        currentItem: null,
        text: {
          title: {
            fr: 'Nos valeurs',
            en: 'Our values'
          },
          content: {
            fr: 'Un retour aux racines académiques de la recherche scientifique, essentiellement française, nous a amené à explorer de nouveaux horizons, certainement ceux de demain, et à interroger la pertinence des stratégies d’investissement traditionnelles.',
            en: `Our mission is to deliver customized systematic and quantitative solutions to our investors, based on academic research and technical expertise while trying to be truly innovative in our axes and themes of research.
<br><br>We are unique in combining a trading experience with a systematic and quantitative approach allowing us to avoid the classic pitfalls of data mining.`
          }
        },
        items: [
          {
            title: {
              fr: 'Humilité',
              en: 'Humility'
            },
            content: {
              fr: `Agnostiques, nous militons pour l’innovation et la créativité pour apporter une lecture plus robuste des marchés financiers devenus de plus en plus complexes à analyser: mieux évaluer les probabilités de fluctuations des prix pour réussir à délivrer de la corrélation positive lorsque les marchés sont performants et de la corrélation négative en cas de sous-performance des indices de référence. Telle est notre obsession.
<br><br>Le choix de partenariats exclusifs avec des instituts de recherche indépendants et une cohabitation plus étroite avec le monde universitaire donnent plus d’envergure à nos innovations. Se réinventer et être de son temps passe par un apprentissage en continu.`,
              en: `Mavericks, Agnostics, Rooted in Academic Research, Evarinvest offers a pioneering interpretation of financial markets and proposes “orthogonal” tools to generate Alpha.
<br><br>In partnership with independent Research Institutes and renowned Schools that share our values, our philosophy and investment approach seek to keep up with Scientific and Technological innovations.`
            }
          },
          {
            title: {
              fr: 'Transparence',
              en: 'Transparency'
            },
            content: {
              fr: `Indépendance en matière de Recherche et Développement,
<br>Rigueur dans le processus d’investissement,
<br>Robustesse des analyses et implémentations.
<br>Liquidité avant tout: tel sera notre pacte de confiance.
<br><br>Utilisation exclusive d’instruments financiers listés sur des marchés standardisés et liquides.`,
              en: `Transparency is a pledge of Trust and a sanity check to better serve our clients every single day. It allows efficiency, liquidity, scalability and competitiveness.`
            }
          },
          {
            title: {
              fr: 'Innovation',
              en: 'Innovation'
            },
            content: {
              fr: `<span data-font="text huge light">Éducation & recherche</span>
<br>La recherche et développement restera la colonne vertébrale de notre activité et le socle de nos innovations.
<br><br>Nous ne cesserons de collaborer avec des instituts de recherche indépendants ou des départements académiques pour améliorer nos connaissances en continu.
<br><br>Nous souhaitons également contribuer à l’essor de notre département R&D en investissant dans l’éducation via des interactions proactives avec des départements spécialisés d’universités et Grandes Ecoles françaises.`,
              en: `<span data-font="text huge light">Education and knowledge</span>
<br>Education will be the backbone of our personal and professional  engagement as inequalities sharpen with Technological disruptions.
<br><br>In the Wild ass’s skin, Honoré de Balzac, a visionary literary genius depicts realistic portrays of the excesses of bourgeoisie decadence: <span data-font="bold">"The exercise of the Will consumes us, the exercise of power destroys us, but knowledge leaves our constitution  in a perpetual...state of calm"</span>.`
            }
          },
        ]
      }
    }
  }
</script>