<template>
  <div class="container">
    <div class="container__outer container__outer--action">
      <div class="disclaimer">
        <div class="block" data-space="mb4">
          <img
            class="w10"
            data-space="mx-auto"
            src="../assets/images/eva-logo.svg"
          />
        </div>
        <div class="disclaimer__text">
          <p
            data-font="menu regular base lh-big"
            v-html="text.content[lang]"
          ></p>
        </div>
        <p
          data-font="menu bold small lh-big"
          data-space="pt4"
          v-html="text.agree[lang]"
        ></p>
        <div data-flex="items-center" data-space="pt2">
          <div
            class="checkbox"
            :class="{ 'checkbox--checked': checked }"
            @click="checked = !checked"
          ></div>
          <p
            class="pointer"
            data-font="menu light medium upper"
            data-space="pl2"
            @click="checked = !checked"
            v-html="text.agreeBtn[lang]"
          ></p>
        </div>
        <div data-flex="justify-between wrap" data-space="pt2">
          <button
            class="disclaimer__btn"
            data-space="mx1 mt2"
            v-html="text.validate[lang]"
            @click="acceptUser"
            :disabled="!checked"
          ></button>
          <button
            class="disclaimer__btn"
            data-space="mx1 mt2"
            v-html="text.reject[lang]"
            @click="rejectUser"
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["lang"],
  data() {
    return {
      checked: false,
      text: {
        content: {
          fr: `<span data-font="medium bold">Bienvenue sur le site d’EVARINVEST AM</span>
<br><br>EVARINVEST AM est autorisée et régulée par l’Autorité des marchés financiers (Numéro d’agrément n° GP-2000011).
<br>Siège social : 48 bd de la Tour Maubourg, 75007 Paris. RCS 877807800 Paris.
<br><br>Vous accéderez au site d’EVARINVEST AM, site destinée exclusivement aux personnes résidant en France ou accédant au site depuis la France.
<br>La visualisation des pages suivantes requiert l’acceptation de votre part des conditions exposées ci-dessous. Si vous n’acceptez pas ces conditions, nous vous invitons à ne pas accéder aux pages suivantes.
<br><br>Cette section du site Internet est uniquement réservée aux investisseurs professionnels au sens de la Directive 2004/39/CE (Directive MIF) et n’est pas destinée aux investisseurs individuels.
<br><br>Ce site vous permet de consulter uniquement les informations relatives aux Fonds qui font l’objet d’une offre publique dans le pays sélectionné. Ce site Internet ne s’adresse pas aux personnes relevant d’une juridiction dans laquelle la publication du site Internet ou son accès est interdit(e) en raison de leur nationalité ou de leur lieu de résidence. Les personnes accédant au site Internet savent qu’elles sont seules responsables du respect des lois et réglementations applicables dans leur pays de résidence et/ou de nationalité.
<br><br>Objectif du site :
<br>Ce site est un site d'information dont l'objectif est de présenter l'activité de gestion de portefeuille d’EVARINVEST AM ainsi que les principales caractéristiques de ses OPC et services. Ce site n’est pas un site transactionnel. Toute information peut être modifiée sans préavis par EVARINVEST AM. Ce site Internet n'est pas destiné aux citoyens ou résidents des États-Unis d'Amérique ou à des «U.S. Persons» tel que ce terme est défini dans le «Regulation S» de la loi américaine de 1933 sur les valeurs mobilières. Aucun Fonds présenté ici ne peut être proposé ou vendu directement ou indirectement aux États-Unis d'Amérique à des résidents et citoyens des États-Unis d'Amérique et à des «U.S. Persons».
<br><br>Traitement des données personnelles
<br><br>EVARINVEST AM collecte des Données à caractère personnel vous concernant auprès des différentes sources suivantes :
<br><br>EVARINVEST AM collecte vos Données à caractère personnel (1) dans le cours normal de sa relation avec vous (p. ex. dans le cadre de la gestion de vos transactions ou de vos investissements dans ses fonds ; (2) que vous choisissez manifestement de rendre publiques, y compris par l’intermédiaire des médias sociaux (EVARINVEST AM peut par exemple recueillir des informations issues de votre/vos profil(s) de réseau social dans la mesure où vous décidez de le(s) rendre accessible(s)).
<br><br>EVARINVEST AM obtient vos Données à caractère personnel (1) lorsque vous les communiquez à EVARINVEST AM (par exemple lorsque vous contactez EVARINVEST AM par e-mail ou par téléphone, ou par tout autre moyen) ; (2) auprès de tiers qui les fournissent à EVARINVEST AM (tels que votre employeur, les clients d’EVARINVEST AM, des agences d’évaluation de crédit, des organismes chargés de l’application des lois, etc.) ; (3) auprès de tiers par l’intermédiaire desquels vous achetez les produits ou services d’EVARINVEST AM ; (4) lorsque vous consultez le site d’EVARINVEST AM ou encore lorsque vous utilisez les fonctions ou ressources proposées sur ou via le site d’EVARINVEST AM (type d’appareil, système d’exploitation, type de navigateur, paramètres du navigateur, adresse IP, paramètres de langue, dates et heures des connexions à un Site et autres informations techniques relatives aux communications), dont certaines peuvent constituer des Données à caractère personnel.
<br><br>La collecte de vos données est réalisée pour les traitements suivants : (1) Processus d’entrée en relation : intégration de nouveaux clients et respect des exigences, politiques et procédures internes d’EVARINVEST AM en matière de conformité ; (2) Marketing/prospection : communication avec vous par quelque moyen que ce soit (y compris par e-mail, téléphone, SMS, réseau social, courrier ou en personne) ; (3) Fourniture de produits et de services à votre intention : gestion des relations et des services connexes, exécution de tâches nécessaires en vue de la fourniture des services requis, communication avec vous dans le cadre de ces services.
<br><br>EVARINVEST AM a mis en œuvre des mesures de sécurité techniques et organisationnelles appropriées pour protéger vos Données à caractère personnel contre la destruction, la perte et l’altération accidentelles ou illicites, la diffusion ou l’accès non autorisé(e) et toute autre forme de Traitement illicite ou non autorisée conformément à la législation applicable. Il vous incombe de vous assurer que les Données à caractère personnel que vous nous transmettez le sont de manière sécurisée.
<br><br>EVARINVEST AM prend toutes les mesures raisonnables en vue d’assurer que vos Données à caractère personnel ne soient traitées que pendant la durée minimale nécessaire au regard des finalités décrites dans le présent Avis.
<br><br>Sous réserve de la législation applicable, vous pouvez disposer de certains droits s’agissant du Traitement de vos Données à caractère personnel traitées par EVARINVEST AM, dont :
<br>- le droit de demander l’accès à, ou des copies de, vos Données à caractère personnel, ainsi que des informations relatives à la nature, au traitement et à la communication de ces Données à caractère personnel ;
<br>- le droit de demander la rectification des éventuelles erreurs dans vos Données à caractère personnel;
<br>- le droit de demander, pour des motifs légitimes ;
<br>- l’effacement de vos Données à caractère personnel;
<br>- le droit de demander le transfert à un autre Responsable du traitement de vos Données à caractère personnel;
<br>- la limitation du Traitement de vos Données à caractère personnel;
<br>- le droit de retirer votre consentement dès lors que vos Données à caractère personnel sont traitées sur cette base ; et
<br>- le droit de déposer plainte auprès d’une Autorité en charge de la protection des données concernant le Traitement de vos Données à caractère personnel.
<br><br>Sous réserve de la législation applicable, vous pouvez également disposer des droits supplémentaires suivants s’agissant du Traitement de vos Données à caractère personnel :
<br>- le droit, pour des raisons tenant à votre situation particulière, de vous opposer au Traitement de vos Données à caractère personnel;
<br>- le droit de vous opposer au Traitement de vos Données à caractère personnel lorsque celui-ci est envisagé à des fins de prospection.
<br><br>Pour exercer un ou plusieurs de ces droits ou pour toute question relative à ces droits, veuillez contacter EVARINVEST AM via l’adresse suivante : evarinvest@evarinvest.com`,
          en: `<span data-font="medium bold">Welcome to EVARINVEST AM website</span>
<br><br>EVARINVEST AM is authorized and regulated by the Autorité des marchés financiers (Approval number n° GP-2000011).
<br>Registered office: 48 bd de la Tour Maubourg 75007 Paris. RCS 877807800 Paris.
<br><br>You will access the EVARINVEST AM site, a site intended exclusively for persons residing in France or accessing the site from France.
<br>Viewing the following pages requires your acceptance of the conditions set out below. If you do not accept these conditions, please do not access the following pages.
<br><br>This section of the website is only reserved for professional investors within the meaning of Directive 2004/39/EC (MiFID) and is not intended for individual investors.
<br><br>This site allows you to consult only information relating to the Funds that are the subject of a public offer in the selected country. This website is not directed at persons in any jurisdiction in which the publication of or access to the website is prohibited by reason of their nationality or place of residence. Persons accessing the website are aware that they are solely responsible for compliance with the laws and regulations applicable in their country of residence and/or nationality.
<br><br>Purpose of the site :
<br>This site is an information site whose objective is to present EVARINVEST AM's portfolio management activity as well as the main characteristics of its UCIs and services. This site is not a transactional site. All information is subject to change without notice by EVARINVEST AM. This website is not intended for citizens or residents of the United States of America or "U.S. Persons" as that term is defined in "Regulation S" of the U.S. Securities Act of 1933. No Funds presented herein may be offered or sold directly or indirectly in the United States of America to residents and citizens of the United States of America and U.S. Persons.
<br><br>Processing of Personal Data
<br><br>EVARINVEST AM collects Personal Information about you from the following sources:
<br><br>EVARINVEST AM collects your Personal Data (1) in the normal course of its relationship with you (e.g., in connection with the management of your transactions or investments in its funds; (2) which you clearly choose to make public, including through social media (e.g., EVARINVEST AM may collect information from your social network profile(s) to the extent that you choose to make it (them) accessible).
<br><br>EVARINVEST AM obtains your Personal Information (1) when you provide it to EVARINVEST AM (for example, when you contact EVARINVEST AM by e-mail or telephone, or by other means); (2) from third parties who provide it to EVARINVEST AM (such as your employer, EVARINVEST AM customers, credit reporting agencies, law enforcement agencies, etc.); (3) from third parties who provide it to EVARINVEST AM (such as your employer, EVARINVEST AM customers, credit reporting agencies, law enforcement agencies, etc.); and (4) from third parties who provide it to EVARINVEST AM (such as your employer, EVARINVEST AM customers, credit reporting agencies, law enforcement agencies, etc.). ); (3) from third parties through whom you purchase EVARINVEST AM's products or services; (4) when you visit the EVARINVEST AM Site or when you use features or resources offered on or through the EVARINVEST AM Site (such as device type, operating system, browser type, browser settings, IP address, language settings, dates and times of connections to a Site and other technical information relating to communications), some of which may constitute Personal Data.
<br><br>The collection of your data is carried out for the following treatments: (1) Relationship initiation process: integration of new customers and compliance with EVARINVEST AM's internal requirements, policies and procedures; (2) Marketing/prospecting: communication with you by any means (including e-mail, telephone, SMS, social network, mail or in person); (3) Provision of products and services to you: management of relationships and related services, performance of tasks necessary for the provision of the required services, communication with you in connection with such services.
<br><br>EVARINVEST AM has implemented appropriate technical and organizational security measures to protect your Personal Data against accidental or unlawful destruction, loss and alteration, unauthorized disclosure or access and any other form of unlawful or unauthorized Processing in accordance with applicable law. It is your responsibility to ensure that the Personal Data you transmit to us is secure.
<br><br>EVARINVEST AM takes all reasonable steps to ensure that your Personal Data is processed only for the minimum period of time necessary for the purposes described in this Notice.
<br><br>Subject to applicable law, you may have certain rights with respect to the Processing of your Personal Data processed by EVARINVEST AM, including :
<br>- the right to request access to, or copies of, your Personal Data, as well as information relating to the nature, processing and communication of such Personal Data;
<br>- the right to request the correction of any errors in your Personal Data;
<br>- the right to request, for legitimate reasons;
<br>- the deletion of your Personal Data;
<br>- the right to request the transfer of your Personal Data to another person responsible for processing your Personal Data;
<br>- the limitation of the Processing of your Personal Data;
<br>- the right to withdraw your consent whenever your Personal Data is processed on this basis; and
<br>- the right to file a complaint with a Data Protection Authority regarding the Processing of your Personal Data.
<br><br>Subject to applicable law, you may also have the following additional rights with respect to the Processing of your Personal Data:
<br>- the right, for reasons relating to your particular situation, to object to the Processing of your Personal Data; and
<br>- the right to object to the Processing of your Personal Data when it is envisaged for canvassing purposes.
<br><br>To exercise one or more of these rights or for any question relating to these rights, please contact EVARINVEST AM via the following address: evarinvest@evarinvest.com.`,
        },
        agree: {
          fr: "En cliquant sur le bouton ci-dessous «J'accepte», vous reconnaissez avoir lu et compris cette page, vous acceptez de vous conformer à son contenu et vous confirmez que vous accédez à ce site Internet conformément aux lois et réglementations de la juridiction ou du pays dans lequel vous résidez.",
          en: 'By clicking on the "I agree" button below, you acknowledge that you have read and understood this page, agree to comply with its contents and confirm that you are accessing this website in accordance with the laws and regulations of the jurisdiction or country in which you reside.',
        },
        agreeBtn: {
          fr: "J'accepte",
          en: "I agree",
        },
        validate: {
          fr: "Je confirme que je suis un client professionnel<br>au sens de la directive mif",
          en: "I confirm that i am a professional customer<br>within the meaning of the mif directive",
        },
        reject: {
          fr: "Je confirme que je suis un client non professionnel<br>au sens de la directive mif",
          en: "I confirm that I am a non-professional client<br>within the meaning of the mif directive",
        },
      },
    };
  },
  methods: {
    acceptUser() {
      if (this.checked) {
        this.$emit("useraccepted");
      } else {
      }
    },
    rejectUser() {
      window.history.back();
    },
  },
};
</script>