import gsap from 'gsap'

export default class Flake {
  constructor (opts) {
    this.lifespan = opts.life
    this.life = opts.life
    this.speedOpts = opts.speed
    this.color = opts.color
    this.random = opts.random
    this.setDirectionOnce = opts.setDirectionOnce
    this.direction = {
      x: 0,
      y: 0
    }
    this.position = {
      x: 0,
      y: 0
    }
    this.speed = null
    this.active = false
    this.randomPos = null
    this.directionSet = false
  }

  reset () {
    this.active = false
    this.life = this.lifespan
    this.direction = null
    this.directionSet = false
  }

  activate (opts, callback) {
    this.setPosition(opts.position)
    this.setSpeed()
    this.setRandom()

    this.animation = gsap.to(this, {
      duration: this.lifespan,
      ease: 'sine.out',
      life: 0,
      onComplete: () => {
        this.reset()
        callback()
      }
    })
  }

  setDirection (pos) {
    if ((this.setDirectionOnce && !this.directionSet) || !this.setDirectionOnce) {
      this.directionSet = true
      this.direction = this.getDirection(pos)

      if (this.setDirectionOnce) {
        // this.direction.x *= 10
        // this.direction.y *= 10
      }
    }
    return this.direction
  }

  getDirection (pos) {
    const dir = {
      x: (this.position.x - pos.x) * this.speed,
      y: (this.position.y - pos.y) * this.speed
    }
    const mod = {
      x: this.randomPos.x * (dir.x > 0 ? 1 : -1),
      y: this.randomPos.y * (dir.y > 0 ? 1 : -1)
    }
    return {
      x: dir.x + mod.x,
      y: dir.y + mod.y
    }
  }

  setSpeed () {
    this.speed = Math.random() * (this.speedOpts.max - this.speedOpts.min) + this.speedOpts.min
  }

  setRandom () {
    this.randomPos = {
      x: Math.random() * this.random,
      y: Math.random() * this.random
    }
  }

  setPosition (pos) {
    this.position.x = pos.x
    this.position.y = pos.y
  }
}