import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Concept from '../views/Concept.vue'
import Complexity from '../views/Complexity.vue'
import Contact from '../views/Contact.vue'
import Entropy from '../views/Entropy.vue'
import Genesis from '../views/Genesis.vue'
import Values from '../views/Values.vue'
import SicavAsymetrix from '../views/SicavAsymetrix.vue'
import LettreAuxPorteurs from '../views/LettreAuxPorteurs'
import MentionsLegales from '../views/MentionsLegales'
import InformationsReglementaires from '../views/InformationsReglementaires'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/concept',
    name: 'concept',
    component: Concept
  },
  {
    path: '/genese',
    name: 'genese',
    component: Genesis
  },
  {
    path: '/entropie',
    name: 'entropie',
    component: Entropy
  },
  {
    path: '/complexite',
    name: 'complexite',
    component: Complexity
  },
  {
    path: '/valeurs',
    name: 'valeurs',
    component: Values
  },
  {
    path: '/sicav-asymetrix',
    name: 'sicav-asymetrix',
    component: SicavAsymetrix
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/lettre-aux-porteurs',
    name: 'lettre-aux-porteurs',
    component: LettreAuxPorteurs
  },  
  {
    path: '/mentions-legales',
    name: 'mentions-legales',
    component: MentionsLegales
  },
  {
    path: '/informations-reglementaires',
    name: 'informations-reglementaires',
    component: InformationsReglementaires
  }
]

const router = new VueRouter({
  routes
})

export const pageOrder = routes.map(route => {
  return route.name
})

export default router
