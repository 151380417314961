export default {
  mounted () {
    if (window.innerWidth >= 1024) {
      if (this.interval === null) {
        this.interval = setInterval(() => {
          const x = this.positionX * this.translateAmount * this.translateDirection
          const y = this.positionY * this.translateAmount * this.translateDirection
          this.translation = 'translateX(' + x + 'px) translateY(' + y + 'px)'
        }, 1000 / 60)
      }
      window.addEventListener('mousemove', this.updatePos)
    }
  },
  destroyed () {
    if (window.innerWidth >= 1024) {
      clearInterval(this.interval)
      window.removeEventListener('mousemove', this.updatePos)
    }
  },
  methods: {
    updatePos (e) {
      this.positionX = e.clientX - window.innerWidth / 2
      this.positionY = e.clientY - window.innerHeight / 2
    }
  },
  data () {
    return {
      translation: '',
      interval: null,
      positionX: 0,
      positionY: 0,
      translateAmount: 0.1,
      translateDirection: -1
    }
  }
}