export default {
  methods: {
    hoverIn () {
      this.$emit('hovered', false)
    },
    hoverOut () {
      this.$emit('hovered', true)
    }
  },

}