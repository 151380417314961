import Particle from './particle'
import cursor from './cursor'
import gsap from 'gsap'

export default {
  uniqueId: 0,
  particles: {},
  orderedParticles: {
    0: {},
    1: {}
  },
  cnv: null,
  ctx: null,
  nbParticles: 0,
  screen: {
    w: 0,
    h: 0
  },
  center: {
    x: 0,
    y: 0
  },
  fade: {
    start: 0,
    end: 0,
    sizeMin: 1
  },
  mouseOffset: {
    x: 0,
    y: 0
  },
  colors: [],
  gradient: null,
  lastFPS: 0,
  FPS: 0,
  ticks: 0,
  spin: null,
  direction: 1,
  speedUp: 1,
  acceleration: null,
  accelerating: null,
  cursor: null,
  init (id, image, options) {
    this.cnv = document.getElementById(id)
    this.ctx = this.cnv.getContext('2d')

    this.fade.sizeMin = options.fade.sizeMin
    this.colors = options.colors
    this.spin = options.spin
    this.acceleration = options.acceleration
    this.mouseOffsetRatio = options.mouseOffsetRatio

    for (let key in this.orderedParticles) {
      if (this.orderedParticles.hasOwnProperty(key)) {
        options.colors.forEach((color, i) => {
          this.orderedParticles[key][i] = []
        })
      }
    }

    this.updateScreenSize(options)
    this.populate(options)

    this.cursor = cursor.init(options.cursor)

    window.requestAnimationFrame(() => this.animate())

    return this
  },
  updateScreenSize (options) {
    this.screen.w = window.innerWidth
    this.screen.h = window.innerHeight
    this.center.x = this.screen.w / 2
    this.center.y = this.screen.h / 2
    this.nbParticles = this.screen.w * this.screen.h / 100000 * options.ratio

    this.cnv.setAttribute('width', this.screen.w)
    this.cnv.setAttribute('height', this.screen.h)
    this.cnv.style.width = this.screen.w + 'px'
    this.cnv.style.height = this.screen.h + 'px'

    const smallSide = Math.min(this.screen.w, this.screen.h)
    this.fade.start = smallSide * options.fade.start
    this.fade.end = smallSide * options.fade.end

    for (let [key, particle] of Object.entries(this.particles)) {
      if (this.particles.hasOwnProperty(key)) {
        particle.setBoundaries(this.screen)
        particle.setPoints(this.screen)
        particle.setDirection(this.screen)
      }
    }

    this.gradient = this.ctx.createRadialGradient(this.center.x, this.center.y, this.fade.end, this.center.x, this.center.y, this.fade.start)
    this.gradient.addColorStop(0, 'rgba(255,255,255,1)')
    this.gradient.addColorStop(1, 'rgba(255,255,255,0)')

    this.populate(options)
  },
  updateMouseOffset () {
    this.mouseOffset = {
      x: (this.center.x - this.cursor.position.x) * this.mouseOffsetRatio / 100,
      y: (this.center.y - this.cursor.position.y) * this.mouseOffsetRatio / 100
    }
  },
  populate (options) {
    if (Object.keys(this.particles).length < this.nbParticles) {
      for (let i = Object.keys(this.particles).length; i < this.nbParticles; i++) {
        const p = new Particle(options, this.screen)
        this.particles[this.uniqueId] = p
        this.orderedParticles[p.filled][p.colorIndex].push(this.uniqueId)
        this.uniqueId++
      }
    }
  },
  accelerate () {
    if (this.accelerating) {
      this.accelerating.kill()
    }
    this.accelerating = gsap.to(this, {
      duration: this.acceleration.duration / 2,
      ease: 'sine.out',
      speedUp: this.acceleration.force,
      onComplete: () => {
        this.accelerating = gsap.to(this, {
          duration:  this.acceleration.duration / 2,
          ease: 'power2.out',
          speedUp: 1,
          onComplete: () => {
            this.accelerating = null
          }
        })
      }
    })
  },
  animate () {
    const now = Date.now();
    if (now - this.lastFPS >= 1000) {
      this.lastFPS = now;
      this.FPS = this.ticks;
      this.ticks = 0;
    }
    this.ticks++;
    let fpsModifier = 60 / this.FPS
    if (fpsModifier > 60 || fpsModifier < 1) { fpsModifier = 1 }

    if (this.cursor.active) {
      this.cursor.activationLoop(fpsModifier)
      this.cursor.updatePosition(fpsModifier)
      this.updateMouseOffset()
    }

    this.ctx.fillStyle = 'rgba(255, 255, 255, 0.6)'
    this.ctx.fillRect(0, 0, this.screen.w, this.screen.h)

    for (let type in this.orderedParticles) {
      if (this.orderedParticles.hasOwnProperty(type)) {
        for (let color in this.orderedParticles[type]) {
          if (this.orderedParticles[type].hasOwnProperty(color)) {
            this.ctx.fillStyle = 'rgb(' + this.colors[color] +')'
            this.ctx.strokeStyle = 'rgb(' + this.colors[color] +')'
            this.ctx.beginPath()
            this.orderedParticles[type][color].forEach(particle => {
              const p = this.particles[particle].get()

              if (!p.spin) {
                if (Math.random() <= this.spin.chance) {
                  const duration = Math.round(Math.random() * (this.spin.duration.max - this.spin.duration.min) + this.spin.duration.min)
                  p.spin = true
                  gsap.to(p, {
                    duration: duration,
                    ease: 'power2.inOut',
                    rotation: Math.PI * duration,
                    onComplete: () => {
                      p.spin = false
                      p.rotation = 0
                    }
                  })
                  gsap.to(p, {
                    duration: duration / 2,
                    ease: 'power2.in',
                    speedModifier: Math.random() * (this.spin.speed.max - this.spin.speed.min) + this.spin.speed.min,
                    onComplete: () => {
                      gsap.to(p, {
                        duration:  duration / 2,
                        ease: 'power2.out',
                        speedModifier: 1,
                      })
                    }
                  })
                }
              }

              p.position.x += (p.direction.x * fpsModifier * p.speedModifier * this.direction * this.speedUp)
              p.position.y += (p.direction.y * fpsModifier * p.speedModifier * this.direction * this.speedUp)

              const vect = {
                x: Math.abs(this.center.x - p.position.x),
                y: Math.abs(this.center.y - p.position.y)
              }
              const dist = Math.sqrt(vect.x ** 2 + vect.y ** 2)

              if (this.direction > 0 && dist < this.fade.end) {
                p.reset(this.screen, true)
              } else if (this.direction < 0 && (vect.x > this.screen.w * 0.55 || vect.y > this.screen.h * 0.55)) {
                p.reset(this.screen, false)
              } else {
                const hypo = Math.sqrt(this.screen.w ** 2 + this.screen.h ** 2) / 2
                const scale = dist / hypo * (1 - this.fade.sizeMin) + this.fade.sizeMin

                p.scaledPoints.forEach((point, j) => {
                  let o = {
                    x: point[0] * scale,
                    y: point[1] * scale
                  }
                  if (p.spin) {
                    const n = {
                      x: Math.cos(p.rotation) * o.x - Math.sin(p.rotation) * o.y,
                      y: Math.sin(p.rotation) * o.x + Math.cos(p.rotation) * o.y
                    }
                    o.x = n.x
                    o.y = n.y
                  }
                  const pos = {
                    x: o.x + p.position.x + (this.mouseOffset.x * p.offsetRatio),
                    y: o.y + p.position.y + (this.mouseOffset.y * p.offsetRatio)
                  }
                  if (j !== 0) {
                    this.ctx.lineTo(pos.x, pos.y)
                  } else {
                    this.ctx.moveTo(pos.x, pos.y)
                  }
                })

              }
            })
            this.ctx.closePath()
            Number(type) === 1 ? this.ctx.fill() : this.ctx.stroke()
          }
        }
      }
    }

    this.ctx.beginPath()
    this.ctx.fillStyle = this.gradient
    this.ctx.arc(this.center.x, this.center.y, this.fade.start, 0, 2* Math.PI)
    this.ctx.fill()
    this.ctx.closePath()

    if (this.cursor.active) {
      this.cursor.draw(this.ctx)
    }

    window.requestAnimationFrame(() => this.animate())
  }
}