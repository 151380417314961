<template>
  <div class="container">
    <div class="container__outer">
      <div class="scroll"></div>
      <div data-flex="items-center column-s" class="container__inner">
        <div data-space="px4 py2" class="mw50 slide3 slide-left">
          <img class="force320" src="../assets/images/EVARISTEGALOIS-bleu.png" />
        </div>
        <div data-space="px4 py2">
          <h2 class="slide4 slide-down" data-font="upper action light main lh-tiny left-s" v-html="text.title[lang]"></h2>
          <h3 class="slide2 slide-down" data-font="text light main lh-tiny left-s" v-html="text.subtitle[lang]"></h3>
          <p class="slide1 slide-down" data-space="pt4"
             :data-font="lang === 'fr' ? 'text light lh-big body' : 'text light lh-big base'"
             v-html="text.content[lang]"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [ 'lang' ],
    data () {
      return {
        text: {
          title: {
            fr: 'Concept',
            en: 'Our Philosophy'
          },
          subtitle: {
            fr: 'Evariste Galois',
            en: 'Evariste Galois'
          },
          content: {
            fr: `EvarInvest, en hommage au mathématicien iconoclaste Evariste Galois, veut sortir des sentiers battus et du dogme économétrique traditionnel.
<br><br>Nous questionnons la pertinence de la théorie moderne et l’efficacité de ses postulats pour offrir des solutions inédites et transparentes.`,
            en: `Evarinvest is our tribute to the French mathematician Evariste Galois.
<br><br>Our philosophy consists in offering an orthogonal view to the classic theory while approaching markets as <span data-font="bold">Complex Systems</span>.
<br>Financial markets are driven by uncertainty, heteroscedasticity, volatility clustering and fat tails.
<br>There is a very little predictive power in realised or implied volatility, implied skew or term structure.
<br>A more robust approach to assess forward asset probability distributions is needed.
<br><br>New considerations have emerged, that characterize financial markets as <span data-font="bold">Complex Systems</span>.
<br>Thermodynamics, Information Theory and Complex System studies provide compelling probability forecasts for price variations.
<br>In particular, <span data-font="bold">Entropy defined as a measure or order and disorder</span>, provides more relevant and complete information than the traditional statistical variance.
<br><br>Our process is exclusively quantitative.
<br>Our aim is to generate <span data-font="bold">absolute return</span> while being <span data-font="bold">long convexity</span> as we are exclusively buyers of <span data-font="bold">listed and liquid</span> short dated options.
<br>Our investors face <span data-font="bold">no gap risk</span> in this approach.`
          }
        }
      }
    }
  }
</script>