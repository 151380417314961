var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[_c('div',{attrs:{"data-col":"grow","data-flex":"justify-center column"}},_vm._l((_vm.items),function(item,i){return _c(item.route ? 'router-link' : 'a',{tag:"component",staticClass:"menu-item",class:[
        item.css || '',
        {
          'menu-item--active': _vm.$route.name === item.route,
          'menu-item--animate': !_vm.menuActive,
        } ],style:([
        _vm.menuActive
          ? {
              transition:
                'transform 0.2s ease ' +
                (0.3 + i * 0.075) +
                's, opacity 0.2s ease ' +
                (0.3 + i * 0.075) +
                's, padding-left .2s ease',
            }
          : '' ]),attrs:{"to":item.route,"href":item.link,"target":item.link ? '_blank' : null}},[_vm._v(" "+_vm._s(item.label[_vm.lang])+" ")])}),1),_c('div',{attrs:{"data-flex":"column"}},[_c('router-link',{attrs:{"to":"lettre-aux-porteurs","data-font":"base menu lh-small"},domProps:{"innerHTML":_vm._s(_vm.lp[_vm.lang])}}),_c('router-link',{attrs:{"data-space":"mt1","to":"mentions-legales","data-font":"base menu lh-small"},domProps:{"innerHTML":_vm._s(_vm.ml[_vm.lang])}}),(_vm.ir[_vm.lang])?_c('router-link',{attrs:{"data-space":"mt1","to":"informations-reglementaires","data-font":"base menu lh-small"},domProps:{"innerHTML":_vm._s(_vm.ir[_vm.lang])}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }