<template>
  <div class="container">
    <div class="container__outer">
      <div class="scroll"></div>
      <div data-flex="column-reverse-s items-center" class="container__inner container__inner--large">
        <div data-space="px4 py2">
          <h2 class="slide3 slide-down" data-font="upper action light main lh-tiny left-s" v-html="text.title[lang]"></h2>
          <p class="slide1 slide-down"
             :data-font="lang === 'fr' ? 'text light lh-big body' : 'text light lh-big base'"
             data-space="pt4" v-html="text.content[lang]"></p>
        </div>
        <div data-space="px4 py2" class="slide2 slide-right mw50">
          <!-- <img class="force320 tr01 relative"
               :style="{ transform: translation }"
               src="../assets/images/PAGE_GENESE.png"/> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import cssCursor from '../assets/js/css-cursor'

  export default {
    mixins: [ cssCursor ],
    props: [ 'lang' ],
    data () {
      return {
        translateAmount: 0.05,
        translateDirection: -1,
        text: {
          title: {
            fr: 'La genèse',
            en: 'About us'
          },
          content: {
            fr: `EvarInvest est né d’une passion forte pour <span data-font="bold">l’innovation</span>, d’une volonté d’<span data-font="bold">indépendance</span>, et d’un <span data-font="bold">enthousiasme effréné</span> à l’idée d’aligner les intérêts de la communauté d’investisseurs aux valeurs fondamentales de transparence et d’efficacité des solutions mises en oeuvre.
<br><br>Ses fondateurs ont collaboré pendant plusieurs années chez Lehman Brothers puis développé, ensemble, des projets ambitieux chez Nomura.
<br><br>Entrepreneurs dans l’âme, anti-conformistes,  nous avons la volonté de mettre nos valeurs fondamentales au service de nos engagements professionnels.`,
            en: `EvarInvest provides Investment Solutions for Institutional clients, Hedge Funds, Family Offices and Pension Funds.
<br><br>Our IT infrastructure and proprietary risk framework allows us to deep dive into all our recommendations and strategies and to fully decompose all risk sensitivities in real time.
<br>In particular we have built a robust <span data-font="bold">Python and SQL</span> based infrastructure adapted to non-linear trading. This infrastructure is the backbone of our applied research laboratory.
<br><br>The founders met at Lehman Brothers and Nomura and have known each other for more than 12 years.
<br><br>We have a strong expertise in derivatives and quantitative trading. In particular, under our tenure, Nomura was voted <span data-font="bold">Volatility House of the Year</span> by Derivatives Week.
<br>We raised hundreds of millions of AUM at Nomura, Credit Suisse and BAML, structuring highly innovative solutions for institutional clients.
<br><br>Education will be the backbone of our personal and professional engagement as inequalities sharpen with Technological disruptions.`
          }
        }
      }
    }
  }
</script>