import Vue from 'vue'
import App from './App.vue'
import router, { pageOrder } from './router'

Vue.config.productionTip = false

Vue.prototype.$direction = (from, to) => {
  return pageOrder.indexOf(to) - pageOrder.indexOf(from) > 0
}
Vue.prototype.$routeName = (current, direction) => {
  let index = pageOrder.indexOf(current) + direction
  return index >= 0 && index < pageOrder.length ? pageOrder[index] : null
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
