<template>
  <div class="container">
    <div class="container__outer">
      <div data-flex="column" class="container__inner">
        <h2
          data-font="upper action main light lh-small"
          data-space="pb4 pt2 px4"
          v-html="title[lang]"
        ></h2>
        <p
          class="legal-mentions"
          data-font="text base light lh-medium"
          data-space="pb2 px4"
          v-html="text[lang]"
        ></p>
        <a
          v-for="dl in dls"
          v-if="dl.path[lang] !== null"
          class="legal-mentions"
          :href="dl.path[lang]"
          download
          data-space="pb2 px4"
          @mouseenter="hoverIn"
          @mouseleave="hoverOut"
          >{{ dl.title[lang] }}</a
        >
        <p
          class="legal-mentions"
          data-font="text base light lh-medium"
          data-space="pb2 px4"
          v-html="text2[lang]"
        ></p>
        <a
          v-for="dl in dls2"
          v-if="dl.path[lang] !== null"
          class="legal-mentions"
          :href="dl.path[lang]"
          download
          data-space="pb2 px4"
          @mouseenter="hoverIn"
          @mouseleave="hoverOut"
          >{{ dl.title[lang] }}</a
        >

        <p
          class="legal-mentions"
          data-font="text base light lh-medium"
          data-space="pb2 px4"
          v-html="text3[lang]"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["lang"],
  data() {
    return {
      title: {
        fr: `Informations réglementaires`,
        en: `Regulatory Information`,
      },
      text: {
        fr: `<span data-font="light big">Information sur la prise en compte des critères ESG</span>
<br><br>Les informations ci-dessous sont le résumé requis en vertu des articles 24 à 49 du règlement 
délégué (UE) 2022/1288 de la Commission.

<br><br>La SICAV Asymétrix (ci-après la « SICAV ») privilégie la mission de l’émetteur en privilégiant 
ceux allouant un pourcentage de leur chiffre d’affaires à des objectifs sociaux ou 
environnementaux tels que la protection des salariés et la sécurité au travail, les 
investissements pour les communautés (par exemple : hôpitaux publics, logement social, 
assurance chômage ou assurance chômage des cadres, le transport interurbain propre ,etc.).

<br><br>Les critères sociaux et sociétaux sont définis selon nous comme l'impact direct ou indirect 
de l'activité de l'entreprise et de sa mission dans son objet social sur toutes les parties 
prenantes : ses collaborateurs, ses clients, ses fournisseurs, et positionnement de l’entreprise 
vis-à-vis des communautés locales avec une transmission tangible de valeurs universelles : 
droits humains (présence de syndicats dans l’entreprise), normes de sécurité, l'emploi des 
personnes handicapées, la formation et l’engagement des salariés pour le pilier S, la 
transparence de la rémunération des dirigeants, la lutte contre la corruption, la féminisation 
des conseils d'administration dans une moindre mesure.

<br><br>Les critères ESG contribuent à la prise de décision du gérant, sans pour autant être un 
facteur déterminant de cette prise de décision.

<br><br>La stratégie d’investissement consiste à concentrer la gestion de trésorerie de la SICAV 
auprès d’ émetteurs à vocation sociale ou environnementale qui répondent à nos 
contraintes de liquidité (quotidienne). Nos instruments monétaires bénéficient d’une 
duration inférieure à un an. 

<br><br>Après exclusion des secteurs interdits et thématiques controversées, l’univers 
d’investissement est de facto fortement réduit pour respecter l’ensemble de nos exigences 
réglementaires (Rating Minimum Investment Grade) tout en appliquant les filtres de 
sélection respectant les critères sociaux et environnementaux. Chaque émetteur est filtré 
selon une méthodologie de scoring interne à EVARINVEST AM et doit disposer d’une 
notation supérieure au seuil défini sur un ensemble de 45 critères sociaux, 
environnementaux et responsables.

<br><br>Puis nous procédons à une analyse ESG permettant d’établir une notation interne pour 
chaque émetteur selon une méthodologie de scoring interne. Cette dernière se base sur un 
ensemble de 45 critères appartenant aux piliers environnemental, social et respectant des 
critères de bonne gouvernance.
`,

        en: `<span data-font="light big">Information on the consideration of ESG criteria</span>
<br><br>Les informations ci-dessous sont le résumé requis en vertu des articles 24 à 49 du règlement 
délégué (UE) 2022/1288 de la Commission.

<br><br>La SICAV Asymétrix (ci-après la « SICAV ») privilégie la mission de l’émetteur en privilégiant 
ceux allouant un pourcentage de leur chiffre d’affaires à des objectifs sociaux ou 
environnementaux tels que la protection des salariés et la sécurité au travail, les 
investissements pour les communautés (par exemple : hôpitaux publics, logement social, 
assurance chômage ou assurance chômage des cadres, le transport interurbain propre ,etc.).

<br><br>Les critères sociaux et sociétaux sont définis selon nous comme l'impact direct ou indirect 
de l'activité de l'entreprise et de sa mission dans son objet social sur toutes les parties 
prenantes : ses collaborateurs, ses clients, ses fournisseurs, et positionnement de l’entreprise 
vis-à-vis des communautés locales avec une transmission tangible de valeurs universelles : 
droits humains (présence de syndicats dans l’entreprise), normes de sécurité, l'emploi des 
personnes handicapées, la formation et l’engagement des salariés pour le pilier S, la 
transparence de la rémunération des dirigeants, la lutte contre la corruption, la féminisation 
des conseils d'administration dans une moindre mesure.

<br><br>Les critères ESG contribuent à la prise de décision du gérant, sans pour autant être un 
facteur déterminant de cette prise de décision.

<br><br>La stratégie d’investissement consiste à concentrer la gestion de trésorerie de la SICAV 
auprès d’ émetteurs à vocation sociale ou environnementale qui répondent à nos 
contraintes de liquidité (quotidienne). Nos instruments monétaires bénéficient d’une 
duration inférieure à un an. 

<br><br>Après exclusion des secteurs interdits et thématiques controversées, l’univers 
d’investissement est de facto fortement réduit pour respecter l’ensemble de nos exigences 
réglementaires (Rating Minimum Investment Grade) tout en appliquant les filtres de 
sélection respectant les critères sociaux et environnementaux. Chaque émetteur est filtré 
selon une méthodologie de scoring interne à EVARINVEST AM et doit disposer d’une 
notation supérieure au seuil défini sur un ensemble de 45 critères sociaux, 
environnementaux et responsables.

<br><br>Puis nous procédons à une analyse ESG permettant d’établir une notation interne pour 
chaque émetteur selon une méthodologie de scoring interne. Cette dernière se base sur un 
ensemble de 45 critères appartenant aux piliers environnemental, social et respectant des 
critères de bonne gouvernance.`,
      },
      dls: [
        {
          title: {
            fr: `Publication des informations relatives aux produits financiers qui favorisent des caractéristiques environnementales ou sociales`,
            en: `Publication des informations relatives aux produits financiers qui favorisent des caractéristiques environnementales ou sociales`,
          },
          path: {
            fr: "./dl/Art 8 SFDR Web Site  VF04042023.pdf",
            en: "./dl/Art 8 SFDR Web Site  VF04042023.pdf",
          },
        },
        {
          title: {
            fr: `Politique d'exclusion`,
            en: `Exclusion policy`,
          },
          path: {
            fr: "./dl/Politique d'exclusion.pdf",
            en: "./dl/Politique d'exclusion.pdf",
          },
        },
      ],
      text2: {
        fr: `<span data-font="light big">Rapport LEC 2023</span>`,

        en: `<span data-font="light big">LEC 2023 Report (French)</span>`,
      },
      dls2: [
        {
          title: {
            fr: `Télécharger le rapport`,
            en: `Download report`,
          },
          path: {
            fr: "./dl/Rapport LEC 2023 - Art 29 version 1024.pdf",
            en: "./dl/Rapport LEC 2023 - Art 29 version 1024.pdf",
          },
        },
      ],
      text3: {
        fr: `
<br><br><span data-font="light big">Politique d’engagement actionnarial</span>
<br><br>A ce jour, le fonds dont EVARINVEST AM assure la gestion financière n’investit pas directement dans des actions. EVARINVEST n’a donc pas mis en place de politique d’engagement actionnariale et plus précisément de politique de vote.
<br><br><span data-font="light big">Politique de traitement des réclamations</span>
<br><br>La présente information est établie conformément aux dispositions du Règlement Général de l’Autorité des Marchés Financiers et à l'instruction AMF n° 2012-07.
<br><br>Elle est destinée à informer les porteurs de parts (ci-après le(s) « Client(s) » d’EVARINVEST AM sur la procédure relative au traitement des réclamations.
<br><br>Seules les déclarations actant du mécontentement du Client de parts envers le professionnel sont concernées, à l'exclusion des demandes d'information, d'avis, de clarification, de service ou de prestation.
<br><br>Personne en charge du traitement des réclamations au sein d’EVARINVEST AM : SELMA SEKKAT
<br><br>Tout Client souhaitant adresser une réclamation à EVARINVEST AM est invité à adresser un courrier à l’adresse suivante :
<br><br><span class="w100 block" data-font="center">EVARINVEST AM 
<br>SELMA SEKKAT
<br>Présidente
<br>48 bd de la Tour-Maubourg
<br>75007 PARIS</span>
<br>Une réclamation peut également être adressée à EVARINVEST AM :
<ul>
<li>par téléphone : 01.87.76.06.57</li>
<li>par courriel : evarinvest@evarinvest.com</li>
</ul>
<br><span data-font="bold">Pour bien s'assurer que sa réclamation nous est bien parvenue, nous recommandons au client de nous l'adresser en demandant un accusé de réception.</span>
<br><br><span data-font="bold">Délais de traitement des déclarations :</span>
<br><br>EVARINVEST AM s'engage à faire parvenir au client un accusé de réception sous un délai de 10 jours, dans l'hypothèse où sa réclamation ne pourrait être traitée plus rapidement. A compter de la date de cet accusé de réception, la demande sera traitée dans un délai qui ne pourra excéder 2 mois.
<br><br><span data-font="bold">Recours :</span>
<br><br>En cas d’insatisfaction quant aux suites données à votre réclamation, vous pouvez faire appel :
<ul>
<li><span data-font="underline">Au médiateur indépendant de l’AMF pour les services de gestion pour le compte de tiers et d’investissements :</span>
<span class="w100 block" data-font="center" data-space="mt2">Autorité des Marchés Financiers
<br>Madame Marielle Cohen-Branche
<br>Médiateur de l'AMF
<br>17 place de la Bourse
<br>75082 Paris Cedex 02
<br><br>Le formulaire de demande de médiation auprès de l’AMF ainsi que la charte de la médiation sont disponibles sur le site
<br><a href="https://www.amf-france.org/fr/le-mediateur" data-font="menu underline" target="_blank">https://www.amf-france.org/fr/le-mediateur</a></span></li>
</ul>
<br>Les procédures sont confidentielles, gratuites, contradictoires et non contraignantes. Chacune des parties peut y mettre fin quand elle le désire et conserve le droit de saisir les tribunaux.
<br><br>Cependant, avant de saisir le médiateur, il est nécessaire que le client effectue sa première démarche auprès de la personne en charge des réclamations au sein de EVARINVEST AM.
<br><br><span data-font="light big">Politique de meilleure sélection</span>
<br><br>Conformément à la réglementation en vigueur, EVARINVEST AM, ci-après la « Société de Gestion » prend toutes les mesures suffisantes pour obtenir le meilleur résultat possible pour ses clients lors de l’exécution d’ordres sur instruments financiers. A cette fin et conformément à la réglementation, la Société de Gestion établit et met en une politique de meilleure sélection des PSI d’exécution à qui elle transmet un ordre de ses Clients pour exécution, lui permettant d’obtenir le meilleur résultat possible et des prestataires de SADIE.
<br><br><span data-font="base semibold action" class="block" data-space="pl2">Sélection et évaluation des PSI d’exécution</span>
<br>La Société de Gestion agit avec toute la compétence et le soin requis dans la sélection des intermédiaires auxquels elle transmet les ordres de ses clients pour exécution. Les critères de sélection des intermédiaires diffèrent selon le type d’actif traité.
<br><br><span data-font="bold">Critères de sélection et d’évaluation des PSI</span>
<br><br>Les principaux critères retenus pour la sélection et l'évaluation des intermédiaires et des contreparties sont :
<ul>
<li>Le coût de l'intermédiation</li>
<li>La qualité de l’exécution : EVARINVEST AM sera très sélective dans son choix d'intermédiaires car une quote-part de l'alpha généré pour nos clients proviendra de la qualité d'exécution de nos stratégies systématiques, la robustesse et l'expertise de nos intermédiaires sera un prérequis essentiel ainsi que la tarification proposée.</li>
<Li>La qualité du traitement administratif (envoi des confirmations, qualité du back office, …etc)</Li>
<Li>La solidité financière et structurelle de l'intermédiaire.</Li>
</ul>
<br>D'autres critères pourront être rajoutes en vue d'améliorer l'évaluation réalisée
<br><br>Une fois par an, les prestations des intermédiaires et contreparties sont évaluées selon les critères définis ci-dessus.
<br><br><span data-font="base semibold action" class="block" data-space="pl2">Sélection et évaluation des prestataires de SADIE</span>
<br>Les services d’aide à la décision d’investissement (« SADIE ») correspondent à des services de recherche et d’analyse fournis par des prestataires externes. Ils ont pour objet d’apporter une valeur ajoutée supplémentaire au travail des gérants.
<br><br>EVARINVEST AM ne recourt pas à ce type de prestataire.
<br><br><span data-font="light big">Résumé de la Politique relative à la gestion des conflits d’intérêts</span>
<br><br>EVARINVEST AM a mis en place une politique de gestion des conflits d’intérêts et mis en place des dispositions spécifiques en termes d’organisation (moyens et procédures) et de contrôle afin de prévenir, identifier et gérer les situations de conflits d’intérêts pouvant porter atteinte aux intérêts de ses clients.
<br><br>Néanmoins, si d’éventuels conflits d’intérêts apparaissent, ces derniers seront gérés dans l’intérêt du client, c’est-à-dire de manière équitable et en lui délivrant une information complète et adaptée.
<br><br>Ainsi EVARINVEST s’autorise en fonction des situations de conflits d’intérêts à :
<ul>
<li>Réaliser l’activité ou l’opération dans la mesure où l’organisation permet de gérer de manière appropriée la situation de conflit d’intérêts potentiel ;</li>
<li>Informer le client dans le cas où certains conflits d’intérêts peuvent subsister et lui communiquer les informations nécessaires sur leur nature et leur origine ;</li>
<li>Le cas échéant, ne pas réaliser l’activité ou l’opération amenant un conflit d’intérêts.</li>
</ul>
<br>Il est rappelé que EVARINVEST AM est une société de gestion indépendante dans le cadre de l’exercice de ses activités.
<br><br>EVARINVEST se doit de gérer tout conflit d’intérêts, de sa détection jusqu’à son traitement approprié. A ce titre EVARINVEST AM a mis en place une organisation permettant de :
<ul>
<li><span data-font="bold">prévenir</span> l’apparition de conflits d’intérêts, par une sensibilisation de l’ensemble de son personnel aux règles et codes de bonne conduite interne et de place, et par la mise en place de règles et des procédures strictes :
<ul>
<li>mise en place d’un système de contrôle interne ;</li>
<li>séparation des fonctions pouvant générer d’éventuels conflits ;</li>
<li>veiller en permanence à ce que l’offre des produits et services que EVARINVEST AM propose à ses clients corresponde bien au profil et à leurs attentes, et ne soit jamais en contradiction avec leurs intérêts ;</li>
<li>enregistrement des conversations téléphoniques provenant des donneurs d’ordres de EVARINVEST AM ;</li>
<li>prohibition des opérations à titre personnel qui ne respecteraient pas les règles fixées par l’entreprise ;</li>
<li>formation ou sensibilisation de l’ensemble du personnel aux bonnes pratiques de la profession ;</li>
</ul>
</li>
<li><span data-font="bold">identifier</span> les situations de conflits d’intérêts pouvant porter atteinte aux intérêts des clients, par l’établissement d’une cartographie des risques de ces conflits d’intérêts. Cette cartographie précise activités ou opérations pour lesquels un conflit d’intérêts est susceptible de se produire. Le dirigeant-RCCI de EVARINVEST AM a notamment pour mission de veiller à la mise à jour de cette cartographie ;</li>
<li><span data-font="bold">gérer</span> les situations de conflits d’intérêts potentiels :
<ul>
<li>en informant de façon complète et objective les clients, en s’interdisant d’user d’arguments tendancieux tout en signalant les contraintes et les risques associés à certains produits ou à certaines opérations ;</li>
<li>en imposant aux collaborateurs de déclarer au RCCI-dirigeant les cadeaux et avantages perçus selon des règles fixées par EVARINVEST AM ainsi que, dès leur survenance, les situations de conflit d’intérêts dans lesquelles ils pourraient se trouver, le cas échéant.</li>
</ul>
</li>
</ul>
<br><span data-font="light big">Résumé de la Politique de rémunération</span>
<br><br>EVARINVEST AM, ci-après la « Société de Gestion », a mis en place une politique de rémunération pour sa gestion conformément aux règles de rémunération stipulées dans la directive OPCVM. La politique de rémunération mise en œuvre est basée sur des principes favorisant une gestion saine et efficace des risques et dans le respect de l'intérêt des clients. EVARINVEST AM s’engage à établir, mettre en œuvre et maintenir des politiques, procédures et pratiques de rémunération permettant de promouvoir une gestion saine et efficace des risques, ce qui constitue l’un des piliers de sa stratégie.
<br><br>La politique de rémunération, ci-après désignée la « Politique » tient compte des exigences organisationnelles et opérationnelles générales de la Société de Gestion ainsi qu’à la nature, la portée et la complexité de ses activités.
<br><br><span data-font="base semibold action" class="block" data-space="pl2">Principes</span>
<br>La politique de rémunération de la société de gestion (i) a été élaborée sur la base d'une évaluation de son organisation interne et en se conformant à sa stratégie économique ainsi qu'à ses objectifs à long terme, dans le cadre d'une gestion saine et de maîtrise de son risque au vu de la nature, la portée et les complexité de ses activités, (ii) n'encourage pas une prise de risque excessive par les collaborateurs, incompatible avec les profils de risque ou les documents constitutifs des OPC, (iii) est basée sur une appréciation de la performance financière et non financière des collaborateurs sur la base de l'application de critères qualitatifs et quantitatifs mesurés; (iv) interdit tout mode de rémunération susceptible de créer des risques excessifs pour la société de gestion, (v) intègre un mécanisme d'ajustement de la rémunération variable tenant compte des risques courants et futurs pertinents, (vi) prône et encourage une gestion saine et efficace du risque.
<br><br><span data-font="base semibold action" class="block" data-space="pl2">Durabilité</span>
<br>Les collaborateurs d’Evarinvest AM doivent particulièrement prendre en compte le risque de durabilité dans leur processus d'investissement et également monitorer rigoureusement tout ce qui pourrait avoir un impact négatif sur ce risque de durabilité. Un mauvais suivi  et respect des indicateurs de durabilité peut pénaliser la rémunération de nos gestionnaires. Pour éviter une prise de risque excessive, la rémunération variable sera fortement réduite, voire totalement reconsidérée, en cas de non-respect des normes éthiques et de nos valeurs extra-financières.
<br><br><span data-font="base semibold action" class="block" data-space="pl2">Gouvernance des rémunérations</span>
<br>La Politique est définie et adoptée par la Direction de la Société de Gestion.
<br><br>Les fonctions de contrôle et de suivi des risques de la Société de Gestion sont également impliquées dans le processus de revue des rémunérations variables au sein de la société et plus spécifiquement du personnel dit « concerné » (ci-après désignées le « Personnel Concerné »).
<br><br>Le Personnel Concerné comprend l’ensemble des collaborateurs d’EVARINVEST AM.
<br><br><span data-font="base semibold action" class="block" data-space="pl2">Principes relatifs à la détermination et au versement des rémunérations (dont rémunérations variables)</span>
<br>La politique s’applique aux rémunérations suivantes :
<ul>
<li><span data-font="bold">La part fixe</span> des rémunérations du collaborateur concerné qui rétribue la capacité du collaborateur à répondre aux critères définis à son poste de manière satisfaisante,</li>
<li><span data-font="bold">La partie variable</span> de la rémunération du collaborateur concerné, qui vise à reconnaitre la performance individuelle, ses contributions et son comportement.</li>
</ul>
<br>Un équilibre approprié est établi entre la part fixe et la part variable de la rémunération globale de chaque collaborateur. À l’issue des entretiens individuels, la Direction d’EVARIVEST AM détermine le montant de la rémunération variable de chaque collaborateur sur la base de critères qualitatifs et quantitaifs en lien avec les résultats de la société et le niveau ses fonds propres réglementaires.
<br><br>La Direction réexamine et statue chaque année sur les différents éléments de la Politique de rémunération. Compte tenu de la taille de la société de gestion, il n’est pas prévu de créer un comité de rémunération indépendant.
<br><br>La Société de Gestion ne versera pas de part variable de rémunération aux collaborateurs concernées par le biais d’instruments ou de méthodes qui facilitent le contournement des exigences réglementaires.
<br><br>Les détails de la politique de rémunération de la société de gestion sont disponibles sur demande à l'adresse du siège social :
<br>EVARINVEST AM 48 bd de la Tour-Maubourg 75007 PARIS. Un exemplaire papier peut être adressé gratuitement à toute personne qui en fait la demande.`,
        en: `<span data-font="light big">Shareholder Engagement Policy</span>
<br><br>To date, the fund managed by EVARINVEST AM does not invest directly in shares. EVARINVEST has therefore not implemented a shareholder engagement policy and more specifically a voting policy.
<br><br><span data-font="light big">Complaints Processing Policy</span>
<br><br>This information has been prepared in accordance with the provisions of the General Regulations of the Autorité des Marchés Financiers and AMF Instruction 2012-07.
<br><br>It is intended to inform unitholders (hereinafter the "Client(s)" of EVARINVEST AM) about the procedure for handling complaints.
<br><br>Only declarations of dissatisfaction of the Client(s) with the professional are concerned, excluding requests for information, advice, clarification, service, or provision.
<br><br>Person in charge of handling complaints within EVARINVEST AM : SELMA SEKKAT
<br><br>Any Client wishing to make a complaint to EVARINVEST AM is invited to send a letter to the following address :
<br><br><span class="w100 block" data-font="center">EVARINVEST AM 
<br>Mr / Mrs SELMA SEKKAT
<br>Cofounder and CEO
<br>48 bd de la Tour-Maubourg
<br>75007 PARIS</span>
<br>A complaint can also be addressed to EVARINVEST AM :
<ul>
<li>by phone : : 01.87.76.06.57</li>
<li>by mail : evarinvest@evarinvest.com</li>
</ul>
<br><span data-font="bold">In order to make sure that the complaint has reached us, we recommend that the customer sends it by requesting an acknowledgement of receipt.</span>
<br><br><span data-font="bold">Processing time for declarations :</span>
<br><br>EVARINVEST AM undertakes to send the client an acknowledgement of receipt within 10 days, in the event that his claim cannot be processed more quickly. From the date of this acknowledgement of receipt, the request will be processed within a period not exceeding 2 months.
<br><br><span data-font="bold">Recourse :</span>
<br><br>If you are dissatisfied with the outcome of your complaint, you can appeal :
<ul>
<li><span data-font="underline">The AMF's Ombudsman for third-party asset management and investment services :</span>
<span class="w100 block" data-font="center" data-space="mt2">Autorité des Marchés Financiers
<br>Madame Marielle Cohen-Branche
<br>Médiateur de l'AMF
<br>17 place de la Bourse
<br>75082 Paris Cedex 02
<br><br>The AMF Mediation Request Form and the Mediation Charter are available on the AMF website
<br><a href="https://www.amf-france.org/fr/le-mediateur" data-font="menu underline" target="_blank">https://www.amf-france.org/fr/le-mediateur</a></span></li>
</ul>
<br><br><span data-font="light big">Best Selection Policy</span>
<br><br>In accordance with current regulations, EVARINVEST AM, hereafter the "Management Company" takes all reasonable steps to obtain the best possible result for its clients when executing orders in financial instruments. To this end and in accordance with the regulations, the Management Company establishes and implements a policy of best selection of execution intermediaries to which it transmits an order from its Clients for execution, enabling it to obtain the best possible result and of research providers.
<br><br><span data-font="base semibold action" class="block" data-space="pl2">Selection and evaluation of Implementing intermediaries</span>
<br>The Management Company acts with all due skill and care in the selection of intermediaries to which it transmits client orders for execution. The criteria for the selection of intermediaries differ according to the type of assets handled.
<br><br><span data-font="bold">Criteria for the selection and evaluation of intermediaries</span>
<br><br>The main criteria used for the selection and evaluation of media outlets and counterparties are :
<ul>
<li>The cost of intermediation</li>
<li>Quality of execution: EVARINVEST AM will be very selective in its choice of intermediaries as a proportion of the alpha generated for our clients will come from the quality of execution of our systematic strategies, the robustness and expertise of our intermediaries will be an essential prerequisite as well as the proposed pricing.</li>
<Li>The quality of the administrative processing (sending of confirmations, quality of the back office, …etc.)</Li>
<Li>The financial and structural robustness of the intermediary.</Li>
</ul>
<br>Other criteria may be added to improve the carried-out valuation
<br><br>Once a year, the services of intermediaries and counterparties are evaluated according to the criteria defined above.
<br<br>Investment Decision Support Services ("IDSS") are research and analysis services provided by external providers. Their purpose is to add value to the work of the managers.
<br><br>EVARINVEST AM does not use this type of service provider.
<br><br><span data-font="light big">Summary of Conflict-of-Interest Management Policy</span>
<br><br>EVARINVEST AM has set up a conflict-of-interest management policy and has put in place specific provisions in terms of organization (means and procedures) and control, in order to prevent, identify and manage conflict of interest situations that may harm the interests of its clients.
<br><br>Nevertheless, if possible, conflicts of interest arise, they will be managed in the client's interest, i.e., in a fair manner and by providing full and appropriate information.
<br><br>Thus, EVARINVEST authorizes itself according to the situations of conflicts of interest to :
<ul>
<li>Carry out the activity or operation insofar as the organization allows the situation of potential conflict of interest to be managed in an appropriate manner.</li>
<li>InInform the client if certain conflicts of interest may remain and communicate the necessary information on their nature and origin.</li>
<li>Where appropriate, not to carry out the activity or operation that gives rise to a conflict of interest.</li>
</ul>
<br><br>EVARINVEST must manage any conflict of interest, from its detection to its appropriate treatment. To this end, EVARINVEST AM has set up an organization that allows :
<ul>
<li><span data-font="bold">Preventing</span> the risks of conflicts of interest, by raising awareness of all its staff to the rules and codes of good internal and market conduct, and by the implementation of strict rules and procedures :
<ul>
<li>setting up an internal control system.</li>
<li>separation of functions that may generate potential conflicts.</li>
<li>constantly ensure that the products and services offered by EVARINVEST AM to its clients correspond to their profile and expectations and are never in contradiction with their interests.</li>
<li>recording telephone conversations from EVARINVEST AM's clients.</li>
<li>prohibition of personal transactions that do not comply with the rules set by the company.</li>
<li>training or sensitization of all staff to the good practices of the profession.</li>
</ul>
</li>
<li><span data-font="bold">Identify</span> situations of conflicts of interest that may harm the interests of clients, by mapping the risks of these conflicts of interest. This mapping specifies the activities or operations for which a conflict of interest is likely to occur. EVARINVEST AM's CEO is in charge of updating this mapping.</li>
<li><span data-font="bold">Manage</span> situations of potential conflicts of interest :
<ul>
<li>by fully and objectively informing clients, refraining from using tendentious arguments while pointing out the constraints and risks associated with certain products or operations.</li>
<li>by requiring employees to declare to the RCCI-directorate the gifts and benefits received according to the rules set by EVARINVEST AM as well as, as soon as they arise, any conflict-of-interest situations they may find themselves in.</li>
</ul>
</li>
</ul>
<br><span data-font="light big">Summary of Remuneration Policy</span>
<br><br>EVARINVEST AM, hereafter the "Management Company", has designed and implemented a remuneration policy that is consistent with the remuneration rules set in the UCITS Directive. The remuneration policy implemented promotes sound and effective risk management and is not inconsistent with the best interests of the Company, the Funds and its/their investors. EVARINVEST AM is committed to establish, implement, and maintain remuneration policies, procedures and practices that promote sound and effective risk management, which is one of the pillars of its strategy.
<br><br>The remuneration policy, hereinafter referred to as the "Policy" considers the general organizational and operational requirements of the Management Company as well as the nature, scope and complexity of its activities.
<br><br><span data-font="base semibold action" class="block" data-space="pl2">Principles</span>
<br>The management company's remuneration policy (i) was drawn up on the basis of an assessment of its internal organization and in accordance with its business strategy and long-term objectives, within the framework of sound management and control of its risk in view of the nature, scope and complexity of its activities, (ii) does not encourage excessive risk-taking by employees, which is incompatible with the risk profiles or constitutive documents of the UCITs, (iii) is based on an assessment of the financial and non- financial performance of employees on the basis of the application of measured qualitative and quantitative criteria; (iv) prohibits any method of remuneration likely to create excessive risks for the management company, (v) incorporates a mechanism for adjusting variable remuneration taking into account relevant current and future risks, (vi) promotes and encourages sound and effective risk management.
<br><br><span data-font="base semibold action" class="block" data-space="pl2">Governance</span>
<br>The Policy is defined and adopted by the Management of the Management Company.
<br><br>The control and risk monitoring functions of the Management Company are also involved in the process of review of the variable remunerations within the company and more specifically of the personnel known as "concerned" (hereinafter referred to as the "Concerned Personnel").
<br><br>The Concerned Personnel includes all employees of EVARINVEST AM.
<br><br><span data-font="base semibold action" class="block" data-space="pl2">Principles relating to the determination and payment of remuneration (including variable remuneration)</span>
<br>The policy applies to the following remuneration :
<ul>
<li><span data-font="bold">The fixed part</span> of the remuneration of the employee concerned which compensates the employee's ability to meet the criteria defined for his or her position in a satisfactory manner,</li>
<li><span data-font="bold">The variable part</span> of the remuneration of the employee concerned, which is intended to recognize individual performance, contributions, and behavior.</li>
</ul>
<br>An appropriate balance is established between the fixed and variable portions of each employee's total remuneration. Following individual interviews, the EVARIVEST AM management determines the amount of variable remuneration for each employee based on qualitative and quantitative criteria in relation to the company's results and the level of its regulatory equity.
<br><br>Each year, the Management reviews and decides on the different elements of the remuneration policy. Given the size of the management company, there are no plans to set up an independent remuneration committee.
<br><br>The Management Company will not pay a variable part of remuneration to the employees concerned by means of instruments or methods that facilitate the circumvention of regulatory requirements.
<br><br>Details of the Management Company's remuneration policy are available on request from the registered office :
<br>EVARINVEST AM 48 bd de la Tour-Maubourg 75007 PARIS. A paper copy may be sent free of charge to any person who so requests.`,
      },
    };
  },
};
</script>