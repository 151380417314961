<template>
  <div class="container">
    <div
      class="container__outer container__outer--small-bot container__outer--stretch"
    >
      <div data-flex="column" class="container__inner">
        <div data-flex="column justify-center" data-col="grow" data-space="py2">
          <h2
            class="slide2 slide-down"
            data-space="px4"
            data-font="upper text light main lh-tiny center"
            v-html="text.title[lang]"
          ></h2>
          <div class="slide1 slide-down" data-space="px4 pt4" data-flex="wrap">
            <!-- <p class="slide2 slide-down" data-space="pt4" data-font="text light text lh-big" v-html="text.content[lang]"></p> -->
          </div>
          <div data-flex="column" data-space="px4 py2" class="slide3 slide-up">
            <p
              data-font="text base regular lh-medium center"
              v-html="text.credentials[lang]"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cursorToggle from "../assets/js/cursor-toggle.js";

export default {
  mixins: [cursorToggle],
  props: ["lang"],
  methods: {
    checkAll() {
      const toCheck = [
        "firstNameInput",
        "lastNameInput",
        "mailInput",
        "messageInput",
      ];
      this.canSend = toCheck.every((input) =>
        this.notes[input].check(this[input])
      );
    },
    checkInput(ref) {
      const valid = this.notes[ref].check(this[ref]);
      this.notes[ref].active = !valid;

      let count = 0;
      for (let e in this.notes) {
        if (this.notes.hasOwnProperty(e) && this.notes[e].active) {
          count++;
        }
      }
      this.nbNotes = count;
      this.checkAll();
    },
    sendMail() {
      this.sending = true;
      this.sendRequest().then((r) => {
        if (r.mailSent === "ok") {
          this.notes.successMessage.active = true;
          this.firstNameInput = "";
          this.lastNameInput = "";
          this.mailInput = "";
          this.messageInput = "";
        } else {
          this.notes.errorMessage.active = true;
        }
        this.sending = false;
      });
    },
    async sendRequest() {
      const response = await fetch("./mail.php", {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          firstName: this.firstNameInput,
          lastName: this.lastNameInput,
          email: this.mailInput,
          message: this.messageInput.replace(/\n/g, "IIILiNeBrEaKIII"),
          subject:
            "Message de " +
            this.firstNameInput +
            " " +
            this.lastNameInput +
            " via evarinvest.com",
        }),
      });
      return response.json();
    },
  },
  computed: {
    containerHeight() {
      return this.nbNotes * 1.6 + "rem";
    },
  },
  data() {
    return {
      sending: false,
      canSend: false,
      nbNotes: 0,
      notes: {
        firstNameInput: {
          fr: 'Le champs "Votre prénom" ne doit pas être vide.',
          en: 'The "Your first name" field must not be empty.',
          check: (val) => {
            return val.length > 0;
          },
          active: false,
          css: "note--error",
        },
        lastNameInput: {
          fr: 'Le champs "Votre nom" ne doit pas être vide.',
          en: 'The field "Your last name" must not be empty.',
          check: (val) => {
            return val.length > 0;
          },
          active: false,
          css: "note--error",
        },
        mailInput: {
          fr: "L'adresse email indiquée n'est pas valide.",
          en: "The indicated email address is not valid.",
          check: (val) => {
            const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
            return re.test(val);
          },
          active: false,
          css: "note--error",
        },
        messageInput: {
          fr: 'Le champs "Votre message" ne doit pas être vide.',
          en: 'The "Your message" field must not be empty.',
          check: (val) => {
            return val.length > 0;
          },
          active: false,
          css: "note--error",
        },
        successMessage: {
          fr: "Votre message a été envoyé avec succès.",
          en: "Your message was successfully sent.",
          active: false,
          css: "note--success",
        },
        errorMessage: {
          fr: "Votre message n'a pas pu être envoyé.",
          en: "Your message could not be sent.",
          active: false,
          css: "note--error",
        },
      },
      firstNameInput: "",
      lastNameInput: "",
      mailInput: "",
      messageInput: "",
      text: {
        title: {
          fr: "Contact",
          en: "Contact",
        },
        content: {
          fr: "Contacter nous à l'adresse evarinvest@evarinvest.com",
          en: "Contact us at the following address evarinvest@evarinvest.com",
        },
        firstNameInput: {
          fr: "Votre prénom",
          en: "Your first name",
        },
        lastNameInput: {
          fr: "Votre nom",
          en: "Your last name",
        },
        mailInput: {
          fr: "Votre adresse email",
          en: "Your email address",
        },
        messageInput: {
          fr: "Votre message",
          en: "Your message",
        },
        sendButton: {
          fr: "Envoyer",
          en: "Send",
        },
        credentials: {
          fr: '<span data-font="bold upper">EvarInvest AM</span><br>48 bd de la Tour-Maubourg 75007 PARIS - RCS 877807800<br>Email: evarinvest@evarinvest.com - Téléphone +33.1.87.76.06.57<br>Agrément AMF n° GP-20000011',
          en: '<span data-font="bold upper">EvarInvest AM</span><br>48 bd de la Tour-Maubourg 75007 PARIS - RCS 877807800<br>Email: evarinvest@evarinvest.com - Phone +33.1.87.76.06.57<br>AMF Agreement n° GP-20000011',
        },
      },
    };
  },
};
</script>