<template>
  <div class="fullWidth">
    <p v-if="scene && showFPS" style="position: fixed; left: 10px; top: 10px;">{{ 'FPS: ' + scene.FPS }}</p>
    <canvas id="scene"></canvas>
  </div>
</template>

<script>
  import Logic from '../assets/js/scene'

  export default {
    props: ['cursor'],
    data () {
      return {
        showFPS: false,
        options: {
          // particle size
          size: {
            min: 0.2,
            max: 0.7
          },
          // particle speed modifier (particle speed is mainly determined by its size)
          speed: {
            sizeRatio: 0.2,
            randomRatio: 0.15
          },
          // animation acceleration
          acceleration: {
            force: 200,
            duration: 2
          },
          // number of particles per 100000 pixels
          ratio: 4,
          colors: [
            '174, 226, 223',
            '235, 235, 235',
            '218, 242, 250'
          ],
          fade: {
            start: 0.25,
            end: 0.125,
            sizeMin: 0.35
          },
          // offset from current position caused by mouse move (in percent of screen size)
          mouseOffsetRatio: 1.2,
          offsetSizeRatio: {
            min: 0.5,
            max: 4
          },
          spin: {
            chance: 0.001,
            duration: {
              min: 2,
              max: 8
            },
            speed: {
              min: 4,
              max: 10
            }
          },
          cursor: {
            scale: 0.6,
            speed: {
              min: 0.12,
              max: 0.4
            },
            color: '181, 234, 252',
            particles: {
              nb: 25,
              size: {
                min: 0.1,
                max: 0.5
              },
              life: 0.5,
              color: '181, 234, 252',
              speed: {
                min: 0.1,
                max: 0.1
              },
              random: 0,
              setDirectionOnce: true,
              addMovement: true
            }
          }
        },
        scene: null,
        lockTimer: null,
      }
    },
    mounted () {
      if (!this.scene) {
        this.scene = Logic.init('scene', 'particleTemplate', this.options)
        window.addEventListener('resize', () => {
          this.scene.updateScreenSize(this.options)
        })
      }
    },
    watch: {
      $route (to, from) {
        if (from && to) {
          window.clearTimeout(this.lockTimer)
          const dir = this.$direction(from.name, to.name)
          this.scene.direction = dir ? -1 : 1
          this.lockTimer = window.setTimeout(() => {
            this.scene.direction = 1
          }, this.options.acceleration.duration * 1000)
        }
        this.scene.accelerate()
      },
      cursor () {
        if (this.cursor) {
          this.scene.cursor.show()
        } else {
          this.scene.cursor.hide()
        }
      }
    }
  }
</script>

<style lang="scss">
  #scene {
    width: 100%;
    height: 100%;
  }
</style>