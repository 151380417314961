<template>
  <div class="container">
    <div class="container__outer">
      <div data-flex="column" class="container__inner">
        <h2 data-font="upper action main light lh-small" data-space="pb4 pt2 px4" v-html="title[lang]"></h2>
        <p class="legal-mentions" data-font="text base light lh-medium" data-space="pb2 px4" v-html="text[lang]"></p>
          <div class="slide1 slide-down" data-flex="justify-around wrap" data-space="pt2">
          <a v-for="dl in dls"
              v-if="dl.path[lang] !== null"
              class="download"
              :href="dl.path[lang]"
              download
              data-space="pt2"
              @mouseenter="hoverIn"
              @mouseleave="hoverOut">{{ dl.title[lang] }}</a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [ 'lang' ],
    data () {
      return {
        dls: [
          {
            title: {
              fr: `Lettre aux porteurs décembre 2021`,
              en: `Letter to shareholders december 2021`
            },
            path: {
              fr: './dl/20211209_SICAV ASYMETRIX_LAP VF.pdf',
              en: './dl/20211209_SICAV ASYMETRIX_LAP VF.pdf'
            }
          }
        ],        
        title: {
          fr: `Lettre aux porteurs`,
          en: `Letter to shareholders`
        },
        text: {
          fr: ``,
          en: ``

        }
      }
    }
  }
</script>