<template>
  <div class="container">
    <div class="container__outer">
      <div class="scroll"></div>
      <div data-flex="items-center column-reverse-s" class="container__inner container__inner--large">
        <div data-space="px4 py2" class="half">
          <h2 class="slide4 slide-down" data-font="upper action light main lh-tiny left-s" v-html="text.title[lang]"></h2>
          <h3 class="slide2 slide-down" data-font="text light main lh-tiny left-s" v-html="text.subtitle[lang]"></h3>
          <p class="slide1 slide-down" data-font="text light body lh-big" data-space="pt4" v-html="text.content[lang]"></p>
        </div>
        <div class="slide3 slide-right half" data-space="px4 py2" data-flex="justify-center">
          <div id="lottie" class="compass" :class="{ 'compass--active': animReady }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import lottie from 'lottie-web'

  export default {
    props: [ 'lang' ],
    mounted () {
      this.anim = lottie.loadAnimation({
        container: document.getElementById('lottie'),
        renderer: 'canvas',
        loop: false,
        autoplay: false,
        path: './lottie/compass/data.json'
      })
      this.anim.addEventListener('DOMLoaded', () => {
        this.animReady = true
        this.anim.playSegments([0, 410], true)
      })
    },
    data () {
      return {
        animReady: false,
        anim: null,
        text: {
          title: {
            fr: 'Entropie',
            en: 'Entropy'
          },
          subtitle: {
            fr: 'Notre boussole<br>intelligente',
            en: 'Our smart compass'
          },
          content: {
            fr: `L’entropie définie comme une mesure d’ordre et de désordre dans la théorie de l’information offre une lecture pertinente des marchés financiers.
<br><br>Notre approche et son implémentation sont donc susceptibles de fournir un outil de prédiction de régimes de marché efficient, tout en étant orthogonal aux fondements de la théorie financière classique.
<br><br>La pertinence de notre approche délivre une bonne définition des régimes de marchés.
<br><br>Nous cherchons à générer un alpha stable en étant exclusivement acheteurs de convexité.`,
            en: `Entropy may be used to detect non-linear dependencies between time series of returns as well as design systemic risk warning indicators.
<br><br>Entropy is somehow related to higher-order moments of a distribution unlike the variance.
<br><br>As such, it is a more efficient tool to measure uncertainty.
<br><br>Entropy is suited for fat tail distributions that govern financial markets in particular.`
          }
        }
      }
    }
  }
</script>