<template>
  <div class="container">
    <div class="container__outer">
      <div class="scroll"></div>
      <div class="container__inner container__inner--large">
        <div data-space="px4 py2">
          <h2
            class="slide3 slide-down"
            data-font="upper action light main lh-tiny"
            v-html="text.title[lang]"
          ></h2>
          <h3
            v-if="text.subtitle[lang] !== null"
            class="slide3 slide-down"
            data-font="text light huge lh-tiny"
            v-html="text.subtitle[lang]"
          ></h3>
          <p
            class="slide2 slide-down"
            data-space="pt4"
            data-font="text light text lh-big"
            v-html="text.content[lang]"
          ></p>
          <div
            class="slide1 slide-down"
            data-flex="justify-around wrap"
            data-space="pt2"
          >
            <a
              v-for="dl in dls"
              v-if="dl.path[lang] !== null"
              class="download"
              :href="dl.path[lang]"
              download
              data-space="pt2"
              @mouseenter="hoverIn"
              @mouseleave="hoverOut"
              >{{ dl.title[lang] }}</a
            >
          </div>
          <h2
            v-if="text_performance.title_f[lang] !== null"
            class="slide3 slide-down"
            data-font="text light huge lh-tiny"
            v-html="text_performance.title_f[lang]"
          ></h2>
          <img src="performance/performance_part_F.png" />
          <p
            v-if="text_performance.title_c[lang] !== null"
            class="slide2 slide-down"
            data-font="text light text lh-big"
            v-html="text_performance.comment_f[lang]"
          ></p>
          <h2
            v-if="text_performance.title_c[lang] !== null"
            class="slide3 slide-down"
            data-font="text light huge lh-tiny"
            v-html="text_performance.title_c[lang]"
          ></h2>
          <img src="performance/performance_part_C.png" />
          <p
            v-if="text_performance.title_c[lang] !== null"
            class="slide2 slide-down"
            data-font="text light text lh-big"
            v-html="text_performance.comment_c[lang]"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cursorToggle from "../assets/js/cursor-toggle.js";

export default {
  mixins: [cursorToggle],
  props: ["lang"],
  data() {
    return {
      dls: [
        {
          title: {
            fr: "Prospectus Asymétrix",
            en: "Asymétrix leaflet",
          },
          path: {
            fr: "./dl/20240528 Prospectus et statuts ASYMETRIX.pdf",
            en: "./dl/20240528 Prospectus et statuts ASYMETRIX.pdf",
          },
        },
        {
          title: {
            fr: "DICI Asymétrix Part F",
            en: "KIID Asymétrix F Share",
          },
          path: {
            fr: "./dl/KIDDOC-2024-05-28-FR-FR-2024-05-28-FR0013517661-ASYMETRIX_ASYMETRIX_action_F.pdf",
            en: "./dl/KIDDOC-2024-05-28-FR-FR-2024-05-28-FR0013517661-ASYMETRIX_ASYMETRIX_action_F.pdf",
          },
        },
        {
          title: {
            fr: "DICI Asymétrix Part C",
            en: "KID Asymétrix C Share",
          },
          path: {
            fr: "./dl/KIDDOC-2024-05-28-FR-FR-2024-05-28-FR0013517653-ASYMETRIX_ASYMETRIX_action_C.pdf",
            en: "./dl/KIDDOC-2024-05-28-FR-FR-2024-05-28-FR0013517653-ASYMETRIX_ASYMETRIX_action_C.pdf",
          },
        },
        {
          title: {
            fr: "Rapport Annuel 2021",
            en: "Annual Report 2021",
          },
          path: {
            fr: "./dl/202112_RA_ASYMETRIX_727001_FR.pdf",
            en: "./dl/202112_RA_ASYMETRIX_727001_FR.pdf",
          },
        },
        {
          title: {
            fr: "Rapport Annuel 2022",
            en: "Annual Report 2022",
          },
          path: {
            fr: "./dl/202212_RA_ASYMETRIX_727001_FR (002).pdf",
            en: "./dl/202212_RA_ASYMETRIX_727001_FR (002).pdf",
          },
        },
        {
          title: {
            fr: "Rapport Annuel 2023",
            en: "Annual Report 2023",
          },
          path: {
            fr: "./dl/202312_RA_ASYMETRIX_727001_FR_CH.pdf",
            en: "./dl/202312_RA_ASYMETRIX_727001_FR_CH.pdf",
          },
        },
        {
          title: {
            fr: "Calculs de scénarios de performance mensuels Part F",
            en: "Monthly performance scenario calculations F share",
          },
          path: {
            fr: "./dl/monthlyperf/MPSKAD-2024-09-30-FR-FR-2024-10-04-FR0013517661.pdf",
            en: "./dl/monthlyperf/MPSKAD-2024-09-30-FR-FR-2024-10-04-FR0013517661.pdf",
          },
        },
        {
          title: {
            fr: "Calculs de scénarios de performance mensuels Part C",
            en: "Monthly performance scenario calculations C share",
          },
          path: {
            fr: "./dl/monthlyperf/MPSKAD-2024-09-30-FR-FR-2024-10-04-FR0013517653.pdf",
            en: "./dl/monthlyperf/MPSKAD-2024-09-30-FR-FR-2024-10-04-FR0013517653.pdf",
          },
        },
        {
          title: {
            fr: "Annexe SFDR",
            en: "SFDR Annex",
          },
          path: {
            fr: "./dl/Annexe SFDR.pdf",
            en: "./dl/Annexe SFDR.pdf",
          },
        },
        {
          title: {
            fr: "Politique d'exclusion",
            en: "Exclusion policy",
          },
          path: {
            fr: "./dl/Politique d'exclusion.pdf",
            en: "./dl/Politique d'exclusion.pdf",
          },
        },
      ],
      text: {
        title: {
          fr: "SICAV Asymétrix",
          en: "ASYMETRIX",
        },
        subtitle: {
          fr: null,
          en: "Our first French SICAV investment fund governed by French law",
        },
        content: {
          fr: `L’objectif de gestion est d’obtenir, sur la durée de placement recommandée de 5 ans: <br>- une performance nette de frais supérieure à l’ESTER capitalisé +3,5 % pour la part F
            <br>- une performance nette de frais supérieure à l’ESTER capitalisé +4 % pour la part C
            <br>- une performance nette de frais supérieure à l’ESTER capitalisé +3,75 % pour la part U
<br><br>Asymétrix adoptera une stratégie de gestion systématique et quantitative basée sur un modèle propriétaire développé par EVARINVEST AM, cherchant à définir des régimes de marché selon différents niveaux d’entropie au travers d’une gestion répondant à trois critères :
<br><br>(i) Liquidité : grâce à une gestion n’ayant recours qu’à des instruments simples listés et négociés portant sur les sous-jacents les plus liquides, cotés sur les principaux marchés réglementés (Europe et Etats-Unis),
<br>(ii) Transparence : grâce à l’implémentation de stratégies optionnelles simples, la valorisation du portefeuille et la décomposition de la performance sont facilement transparisées, et
<br>(iii) Réactivité : en utilisant des maturités courtes.
<br><br>Dans des conditions normales de marché, la volatilité annuelle de la SICAV devrait être comprise entre 4% et 7%, sans que ceci ne constitue une contrainte de gestion pour EVARINVEST AM.
<br><br>En effet, dans des conditions de marché de volatilité plus élevée, la contrepartie de primes payées plus importantes par l’OPCVM devrait se traduire par une performance également plus conséquente dans le cas où le choix de gestion se révèlerait pertinent.
<br><br>L’asymétrie du portefeuille implique une perte limitée aux primés dépensées, qui est potentiellement récompensée grâce à la convexité de positions exclusivement acheteuses. Le profil de rendement / risque attendu sur la SICAV est donc asymétrique. La perte maximale annuelle autorisée sur l’OPCVM est de 20%.
<br><br>L’équipe de gestion adopterait alors une gestion prudente de type monétaire jusqu’au prochain exercice de la SICAV. L’OPCVM mettra exclusivement en œuvre sa stratégie par l’achat d’options d’achat (call) ou l’achat d’options de vente (put) portant sur les indices Eurostoxx 50 et S&P 500.
<br><br>ASYMETRIX a été lancée le 24 juillet 2020. Les actifs sous gestion s'élèvent à <span data-font="bold">109mEUR</span> au 10 décembre 2021.`,
          en: `ASYMETRIX, was launched July 24th 2020. It is a daily liquidity French UCITs SICAV with current AUM around <span data-font="bold">109mEUR</span> as of December 10th 2021.
<br>Its Bloomberg Code is <span data-font="bold">ASYMTRX FP</span>.
<br><br>Our SICAV adopts an <span data-font="bold">orthogonal</span> and <span data-font="bold">innovative</span> quantitative management process.
<br>Our portfolio is composed of strategies that are transparent and highly scalable.
<br><span data-font="bold">- Orthogonal</span> : The Entropy toolkit enables us to detect markets regimes and to implement a practical solution generating a stable alpha, being long listed options and in any case never short any optionality.
<br><span data-font="bold">- Innovative</span> : our strategy implementation uses exclusively highly liquid, transparent, listed and observable instruments based on tradable end of day prices.
<br>There is no model bias and <span data-font="bold">no gap risk</span>, our investors only risking the monthly premium spent.
<br>Due to the inherent high level of liquidity, positions can be closed intraday with very limited market impact. Our SICAV is a <span data-font="bold">solvency-friendly</span> Equity solution.
<br><br>Entropy, as a measure of order or disorder as defined in Shannon's Information Theory, is our market regime compass. Our model focuses on <span data-font="bold">three</span> different regimes :
<br><br><span data-font="bold">- Equilibrium Regime</span>, which is a low entropy order or disorder regime.
<br><span data-font="bold">- Trend Regime</span> : Entropy trends towards increasing order or increasing disorder regimes.
<br><span data-font="bold">- Excess</span> : Extreme regime in entropy levels lead to excess order or excess disorder, implying a future reversal of market trends.`,
        },
      },
      text_performance: {
        title_f: {
          fr: `<br><br>Performance passée part F`,
          en: `<br><br>Performance of share class F`,
        },
        comment_f: {
          fr: `Performance en EUR - Date de première VL 24/07/2020`,
          en: `Performance in EUR - First NAV date 24/07/2020`,
        },
        title_c: {
          fr: `<br><br>Performance passée part C`,
          en: `<br><br>Performance of share class C`,
        },
        comment_c: {
          fr: `Performance en EUR - Date de première VL 22/02/2021
<br><br> Le règlement PRIIPs stipule que la performance ne doit être divulguée que pour les années complètes.
<br> Les années vides correspondent aux années où le fonds n'avait pas encore été lancé ou à l'année de son lancement si celui-ci n'a pas eu lieu en début d'année.`,
          en: `Performance in EUR - First NAV date 22/02/2021
<br><br>The PRIIPS regulation states that performance should only be disclosed for complete years.
<br>Empty fields correspond to those years in which the fund had not been launched yet or the year of its launch if it not occurred at the beginning of the year.`,
        },
      },
    };
  },
};
</script>