export default {
  data () {
    return {
      startPoint: {
        x: null,
        y: null
      },
      swipeAmount: 40
    }
  },
  created () {
    window.addEventListener('touchstart', this.handleStart)
    window.addEventListener('touchmove', this.handleMove)
  },
  destroyed () {},
  methods: {
    handleStart (e) {
      const touch = e.touches[0]
      this.startPoint.x = touch.clientX
      this.startPoint.y = touch.clientY
    },
    handleMove (e) {
      const touch = e.touches[0]
      if (touch.clientY !== this.startPoint.y || touch.clientX !== this.startPoint.x) {
        const dif = {
          x: this.startPoint.x - touch.clientX,
          y: this.startPoint.y - touch.clientY
        }
        if (Math.abs(dif.x) > Math.abs(dif.y)) {
          if (dif.x > this.swipeAmount) {
            this.swipeLeft()
          } else if (dif.x < -this.swipeAmount) {
            this.swipeRight()
          }
        } else {
          if (dif.y > this.swipeAmount) {
            this.swipeUp()
          } else if (dif.y < -this.swipeAmount) {
            this.swipeDown()
          }
        }
      }
    },
    swipeLeft () {
      console.log('No method assigned to swipe left')
    },
    swipeRight () {
      console.log('No method assigned to swipe right')
    },
    swipeDown () {
      console.log('No method assigned to swipe down')
    },
    swipeUp () {
      console.log('No method assigned to swipe up')
    },
  }
}